@import "vars";

@media only screen and (max-width : 1200px) {
  .top-line__right-stock {
    display: none; }

  .mobile-stock {
    max-width: 200px;
    width: 100%;
    display: block;
    margin: auto;
    margin-top: 15px;
    transform: translateX(10px); }

  .top-line__right {
    display: flex;
    justify-content: space-between;
    max-width: 240px;
    width: 100%; }

  .stock__main {
    margin-right: 0; }

  .top-line__left-lang {
    margin-right: 0; }

  .promotion-slider__type1 {
    .promotion__content-btn {
      margin-top: 67px; }

    .promotion__content-title {
      margin-bottom: 35px; } }

  .promotion__content-title {
    font-size: 43px;
    line-height: 52px; }

  .promotion__content-text {
    margin-left: 0;
    font-size: 26px;
    line-height: 32px; }

  .middle-line {
    padding: 25px 15px 25px; }

  .middle-line__wrapper-left {
    width: 67%; }

  .middle-line__wrapper-right {
    display: block;
    text-align: center; }

  .middle-line__email {
    display: block;
    margin-bottom: 20px;
    margin-right: 0; }

  .middle-line__tel {
    display: block;
    margin-bottom: 20px;
    margin-right: 0; }

  .middle-line__phones {
    margin-right: 0; }

  .middle-line__telegram {
    margin-left: 35px; }

  .top-line {
    padding: 25px 0 15px 0; }

  .top-line__menu {
    display: none; }

  .top-line__tools {
    display: none; }

  .burger {
    display: block; }

  .right-menu {
    display: block; }

  .right-menu__active {
    transform: translateX(0%); }

  .bottom-line__list {
    display: none; }

  .mobile {
    padding-top: 50px; }

  .mobile-content {
    margin: 0 auto; }

  .mobile-content__wrapper {
    text-align: center; }

  .mobile-content__image {
    display: none; }

  .mobile-content__link {
    display: inline-flex; }

  .mobile-content__text {
    max-width: 100%; }

  .mob__link {
    justify-content: center; }

  .content__wrap {
    padding: 0; }

  .card__top {
    flex-wrap: wrap;
    justify-content: center; }

  .card__top-wrap {
    display: flex;
    justify-content: center; }


  .card__top-number {
    display: none; }
  .cards-view_content {
    padding-top: 50px; }

  .cards-view_content-title {
    padding-top: 60px;
    font-size: 42px;
    line-height: 58px; }

  .cards-view_content-wrap-text {
    margin-bottom: 50px; }

  .mobile__image {
    display: none; }

  .mobile-vision {
    padding: 50px 0; }
  .mobile-vision__image {
    display: none; }

  .mobile__text {
    max-width: none; }

  .mobile__link {
    display: flex;
    justify-content: center; }

  .consultation-form {
    margin-right: 0; }

  .howConnect__title--video {
    padding-left: 0; }


  .mobile-content {
    padding-bottom: 0; }

  .tv__speak-list {
    justify-content: space-evenly; }

  .order__text {
    padding-right: 12px; }

  .thesis {
    padding: 35px; }

  .sysadmin__block-info {
    .block-info__column:nth-child(odd) {
      flex-basis: 50%; }
    .block-info__column:nth-child(even) {
      flex-basis: 50%; } }

  .phone-support__content {
    margin-right: 20px; }

  .rate-slider,
  .rate-slider__business {
    .slick-arrow {
      bottom: -43px;
      top: auto;
      margin: -8px; } }

  .rate-slider,
  .rate-slider__business {
    .slick-arrow.slick-prev {
      left: 40%; } }

  .rate-slider,
  .rate-slider__business {
    .slick-arrow.slick-next {
      right: 40%; } }

  .cytys__preview-image {
    width: 118%;
    right: -440px; }

  .product-view-block1 {
    padding-bottom: 0; }

  .block1-list {
    li {
      margin-left: 0;
      &:last-child:after {
        width: 430px; } } }

  .product-form {
    margin-top: 140px; }

  .home3 {
    padding-bottom: 70px; }

  .home-form {
    padding: 50px 0; }

  #homeForm {
    max-width: 500px;
    margin: 0 auto;
    margin-top: 35px; }

  .tariff-list-title_new {
    max-width: none; } }

@media only screen and (max-width : 991px) {
  body {
    overflow-x: hidden; }

  .phone-support {
    padding: 50px; }
  .middle-line {
    justify-content: space-around;
    padding: 15px 15px 15px;
    padding: 15px 15px 15px; }

  .middle-line__wrapper-left {
    width: 50%;
    padding-bottom: 40px; }

  .top-line__right {
 }    //margin-right: 40px

  .middle-line__wrapper-right {
    margin-top: 30px; }

  .middle-line__logo {
    width: 90%; }

  .middle-line__promotion {
    display: none; }

  .options-connect__checkbox-wrapper {
    display: block;
    max-width: 265px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: left; }

  .options-connect__switch {
    margin-bottom: 25px; }

  .options-connect__select-checkbox {
    margin: 0 auto; }

  .our-clients__items-logo {
    margin: 6px; }

  .wrapper {
    padding: 0; }

  .news-slider__item {
    width: 100%;
    margin: 10px; }

  .footer__inner {
    display: none; }

  .footer__accordion {
    display: block;
    justify-content: space-between;
    padding-left: 10%; }

  .footer-link {
    justify-content: space-around; }

  .link_tp {
    padding-top: 40px; }

  .middle__title {
    font-size: 43px;
    line-height: 53px; }

  .chooseTariffs {
    padding: 50px 0; }

  .chooseTariffs__business {
    padding: 70px 0; }

  .HowConnect-content__title {
    font-size: 43px; }

  .connection-check__form-inner {
    display: block;
    text-align: center; }

  .connection-check-btn {
    margin-left: auto;
    margin-right: auto; }
  .connection-check__form-input {
    display: block;
    margin: 0 auto 20px; }

  .connection-check__form-btn {
    margin-left: 0; }

  .info__title {
    font-size: 43px; }

  .mobile,
  .router {
    padding-top: 30px; }

  .services {
    padding: 30px 0 0; }

  .rate {
    padding: 30px; }

  .info__text {
    padding: 0 20px; }

  //.calculate__form
  //  padding-left: 0

  .news {
    padding: 30px; }

  .news-slider .slick-dots {
    bottom: -15px; }
  .services-card__item {
    width: calc(100%/2 - 20px); }

  .application-inner__wrapper {
    display: block; }

  .application-capability {
    justify-content: center; }

  .capability-img {
    width: 36%; }

  .application-about {
    display: flex;
    justify-content: space-around;
    max-width: 100%;
    padding-left: 0;
    padding-top: 30px; }

  .about-text {
    max-width: 390px; }

  .about-link {
    position: static;
    display: block;
    margin: auto 0;
    padding-right: 6%;
    .btn__link {
      margin-left: 0;
      & + .btn__link {
        margin-top: 10px; } } }

  .HowConnect-content__list {
    padding-left: 20px; }

  .HowConnect-feedback__title {
    padding-left: 0; }

  .feedback-link {
    display: block;
    margin-top: 10px;
    margin-left: 0; }

  .mainCards {
    padding: 25px 0; }
  .cards-view_content {
    padding-top: 30px; }

  .cards-list {
    margin: 0 10px 10px; }

  .card-item {
    width: 50%; }
  .tariff-result {
    padding: 30px 10px; }
  .tariff__inner {
    padding-top: 30px; }

  .tariff-business__tabs {
    padding-top: 30px; }

  .tariff-tabs {
    margin-top: 30px; }

  .tariff-content {
    padding: 20px 10px; }

  .tariff-tabs > .nav-tabs > li {
    width: calc(100%/4 - 30px); }

  .tariff-tabs>.nav-tabs>li {
    margin: 0 15px 20px; }

  .consultation-content {
    padding-right: 20px; }

  .howConnect-feedback__title {
    padding-left: 0; }

  .block-info__column {
    flex-basis: 100%; }

  .offer__ourClients-back {
    background-size: cover;
    height: 500px; }

  .column__title-width {
    max-width: 100%; }

  .block-info__inner {
    margin: 0; }

  .colocation-info-provide__inner {
    justify-content: space-between; }

  .colocation-info-provide__list-item {
    &:nth-child(odd) {
      width: 45%; }
    &:nth-child(even) {
      width: 45%; } }

  .colocation-info-param__inner {
    padding-left: 0; }

  .phone-columns__wrap {
    display: block; }

  .phone-col-1,
  .phone-col-2,
  .phone-col-3 {
    max-width: 100%;
 }    //margin-left: 60px

  .phone__col:not(:last-child) {
    margin-bottom: 40px; }

  .phone-tariff__card-item {
    flex-basis: 50%;
    padding: 0 30px; }

  .phone-tariff__card-wrapper {
    margin: 0; }

  .consultation__inner {
    display: block; }

  .consultation-content {
    max-width: 100%;
    text-align: center; }

  .consultation__title {
    text-align: center;
    margin-bottom: 25px; }

  .consultation__text {
    margin-bottom: 25px;
    font-size: 24px; }
  .consultation-form {
    margin: 50px auto 0; }

  .consultation__subtext {
    font-size: 21px; }

  .phone-support__form {
    margin: 25px auto; }

  .phone-support__text {
    max-width: 100%;
    text-align: center; }

  .phone-support__content {
    max-width: 100%;
    text-align: center; }

  .phone-support__numb {
    text-align: center; }
  .column__title {
    text-align: center; }

  .block-info__bus-internet {
    padding: 30px 0; }

  .block-info__title {
    text-align: center;
    margin-bottom: 40px; }

  .block-info__column {
    margin-bottom: 60px; }

  .consultation__title-bus {
    text-align: center; }

  .consultation__title-home {
    text-align: center; }

  .tv__header-text {
    font-size: 28px; }

  .btn__tv {
    padding-left: 42px;
    padding-right: 42px; }
  .tv__nav-wrapper {
    justify-content: space-evenly; }

  .tv__nav-item {
    flex-basis: 280px; }

  .vols__block-info .column__title {
    text-align: left; }

  .order {
    .consultation__subtext {
      max-width: 100%;
      margin-bottom: 20px; }
    .consultation-form {
      margin: 0 auto; } }

  .consultation__numb {
    margin-bottom: 15px; }
  .order__title {
    max-width: 100%;
    text-align: center; }
  .order__text {
    padding-right: 0;
    max-width: 100%; }

  .col-width {
    max-width: 100%; }

  .sysadmin__block-info {
    .block-info__column:nth-child(odd) {
      flex-basis: 100%; }
    .block-info__column:nth-child(even) {
      flex-basis: 100%; }
    .column__img {
      margin: 0 auto 18px; }
    .block-info__column {
      margin-bottom: 35px;
      &:last-child {
        margin-bottom: 35px; } } }

  .sysadmin-sertification__content {
    margin-left: 50px; }

  .cancel-on-png, .cancel-off-png, .star-on-png, .star-off-png, .star-half-png {
    font-size: 35px; }


  .lead__text {
    font-size: 17px;
    line-height: 21px;
    margin-bottom: 25px; }

  .voted-operators__title {
    margin-bottom: 25px; }


  .homeblur {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(6px); }


  .site-error {
    padding: 40px 0 150px; }
  .site-error__title {
    font-size: 270px;
    line-height: 240px; }

  .cytys__preview-image {
    display: none; }

  #callModal {
    h3 {
      text-align: center; } }

  .call-body {
    padding: 30px 20px; }

  #callForm {
    display: block;
    text-align: center; }

  .callInput {
    display: block;
    margin: 0 auto; }

  .callSubmit {
    display: block;
    margin: 30px auto; }

  #callTimer {
    display: block;
    margin: 0 auto; }

  .block3-img {
    height: 227px; }

  .product-price, .product-view-top h1, .block5-h4, .block1-h4, .block2-h4, .block3-h4, .block4-h4, .product-form-h4, .product-form-phone, .product-view-slider-h4 {
    font-size: 38px; }

  .block5-text, .block1-text, .block1-list li, .block2-text, .block4-text, .product-form-text, .product-form-bottom-text, .product-view-slider-text, .tariff-list_new li, .home-view .connect-list, .home_bottom-text {
    font-size: 20px; }

  .block5-list {
    flex-wrap: wrap;
    li {
      max-width: none;
      width: calc(50% - 7px);
      margin-bottom: 10px;
      img {
        width: 100%; } } }

  .block1-list li:last-child:after {
    width: 400px; }

  .block1-list {
    margin: 55px 0; }


  .product-view-block2 {
    height: 400px;
    background-size: cover; }

  .product-view-block4 {
    padding: 60px 0;
    background-size: cover; }

  .product-form {
    margin-top: 40px;
    padding: 50px; }

  .home1-text, .home2-h2, .home3-h2, .home4-h2, .home5-h2, .home6-h2, .home7-h2, .tariff-list-title_new, .home-view .connect-h3, .home_bottom-h3 {
    font-size: 38px;
    line-height: 48px;
    margin-bottom: 0; }

  .home3-text {
    font-size: 26px;
    margin-top: 22px; }

  .home5-question, .home5-number, .home7-text {
    font-size: 24px; }

  .tariff-list_new {
    display: block;

    li {
      width: 100%; } }

  .home-view {
    .total-list-item-text.item-text-main, .total-list-item-text {
      max-width: 70%; } } }

@media only screen and (max-width : 768px) {
  .pay-h1 {
    width: calc(100% - 120px);
    font-size: 24px; }

  .btnCall, #openChat {
    span {
      display: none; } }

  .feedback-question {
    font-size: 16px;
    margin: 5px 4px;
    padding: 4px 11px; }

  .feedback-textarea {
    margin-top: 30px; }

  .right-stock__button {
    margin-left: 0; }

  .top-line__right {
    max-width: 225px;
    width: 100%; }

  .cards-list {
    display: block;
    margin: 0 60px 10px; }

  .mobile .mobile-content {
    padding-bottom: 0; }

  .card-item {
    width: 100%; }

  .tariff-result__checkbox-wrapper {
    display: block; }

  .tariff-result__checkbox {
    margin-bottom: 30px; }

  .total-list-item-text {
    font-size: 17px; }

  .nowrap {
    white-space: nowrap; }

  .tariff-tabs > .nav-tabs > li {
    width: calc(100%/3 - 30px); }

  .rate-variables-business {
    display: block; }

  .tariff-business__tabs {
    border-bottom: none; }

  .tariff-business__tabs .tabs-list {
    display: none; }

  .tariff__label {
    display: block; }

  .tariff-tabs > .nav-tabs > li > a {
    font-size: 42px;
    line-height: 1;
    vertical-align: center; }

  .tariff-business__tabs > .nav-tabs > li {
    padding-right: 10px;
    padding-left: 10px; }

  .tariff-business__tabs > .nav-tabs > li > a {
    text-align: center;
    min-width: 145px;
    padding-bottom: 10px; }

  .mobile-vision {
    padding: 20px 0; }

  .consultation,
  .order {
    padding: 20px 0; }

  .consultation__inner {
    display: block; }

  .consultation__title {
    text-align: center; }

  .consultation-content {
    padding-right: 0px;
    text-align: center;
    margin: 0 auto; }

  .consultation-form {
    margin: 20px auto 0; }

  .howConnect__list {
    margin-bottom: 50px; }

  .chooseTariffs {
    background-size: cover; }
  .chooseTariffs__business {
    padding: 30px 0; }

  .chooseTariffs__business-title {
    font-size: 30px;
    line-height: 42px; }

  .column__title {
    font-size: 22px; }

  .calculate__form-item-last {
    display: block;
    & > .calculate__list-item {
      margin-left: 0;
      margin-top: 30px; } }

  .offer__check-title,
  .offer__check-text,
  .offer__case-text,
  .offer__ourClients-title {
    margin-bottom: 30px; }

  .offer__check-text {
    font-size: 26px;
    line-height: 32px; }

  .info-business__column {
    margin-bottom: 30px; }

  .info-business__column:last-child {
    margin-bottom: 0; }

  .info-business__list {
    max-width: 450px; }

  .colocation-info-provide__inner {
    display: block; }

  .colocation-info-provide__list {
    max-width: 100%; }

  .colocation-info-provide__list-item {
    &:nth-child(odd) {
      width: 100%; }
    &:nth-child(even) {
      width: 100%; } }

  .colocation-info-services__inner {
    display: block;
    text-align: center; }

  .colocation-info-services__text1 {
    max-width: 100%; }
  .colocation-info-services__text2 {
    max-width: 100%; }
  .colocation-info-services__text3 {
    max-width: 100%; }

  .colocation-info-param__inner {
    display: block;
    padding-left: 50px; }

  .colocation-info-param__item {
    max-width: 100%; }

  .param__item-w {
    max-width: 100%; }

  .param__item {
    display: block; }
  .offer,

  .phone-info,
  .connection-check,
  .info,
  .application,
  .howConnect,
  .colocation-info,
  .router,
  .mobile,
  .order,
  .phone-info,
  .news,
  .video-apply,
  .block-info-video__2-row,
  .block-info-video__1-row,
  .block-info-video__title,
  .colocation-info-provide,
  .block-info__bus-internet,
  .phone-columns,
  .tariff__content,
  .vols__block-info,
  .tv__app,
  .pages,
  .voted-installers,
  .sysadmin-view,
  .vols__about,
  .voted-operators,
  .vlan__about,
  .phone-tariff__calc,
  .vlan__block-info,
  .phone-support {
    padding: 25px 0; }

  .phone-support__form {
    margin: 25px auto 0; }

  .tv__nav,
  .tv__speak {
    padding: 25px 0 0; }


  .rate {
    padding: 25px 0 60px; }

  .rate-slider,
  .rate-slider__business {
    .slick-arrow.slick-prev {
      left: 30%; } }

  .rate-slider,
  .rate-slider__business {
      .slick-arrow.slick-next {
        right: 30%; } }

  .tv__speak-list-item {
    margin-bottom: 25px; }
  .phone-tariff__card-item {
    padding: 0 15px; }

  .phone__view > .tariff-tabs > .nav-tabs > li {
    width: calc(100%/2 - 30px); }

  .phone__view > .tariff-tabs > .nav-tabs > li > a {
    font-size: 30px; }

  .tariff__content {
    padding-left: 15px;
    padding-right: 15px; }

  .middle__title {
    font-size: 28px;
    line-height: 35px; }

  .mobile__title {
    font-size: 28px;
    line-height: 38px; }

  .howConnect-feedback__text,
  .howConnect-feedback__title {
    font-size: 24px;
    line-height: 28px; }

  .rate-item__title span {
    font-size: 62px;
    line-height: 76px; }

  .rate-item__price span {
    font-size: 38px; }

  .rate-item__list {
    font-size: 21px;
    line-height: 26px; }

  .info-business__column-title {
    font-size: 24px; }

  .phone-bus-title {
    margin-bottom: 20px; }

  .tv__logo {
    width: 250px; }
  .tv__header-inner {
    padding: 75px 0 80px; }

  .tv__header-text {
    font-size: 22px; }
  .tv__header-content {
    padding: 0 15px; }

  .btn__tv {
    font-size: 18px; }

  .tv__view > .tariff-tabs > .nav-tabs > li {
    width: calc(100%/2 - 30px); }

  .block-info__title-tv {
    text-align: center;
    margin-bottom: 20px; }

  .block-info__column {
    margin-bottom: 25px; }

  .settings-tv__main-title {
    font-size: 28px;
    line-height: 35px; }

  .vols__about-item-text {
    font-size: 24px;
    line-height: 32px; }

  .vlan__about-inner,
  .vlan__about-list-title {
    font-size: 24px;
    line-height: 32px; }

  .vols__about-item-title {
    margin-bottom: 15px; }

  .thesis {
    padding: 25px; }

  .thesis-list-item {
    font-size: 24px;
    line-height: 34px; }

  .vols__list-item {
    &:nth-child(1) {
      max-width: 100%; }
    &:nth-child(2) {
      max-width: 100%; }
    &:nth-child(3) {
      max-width: 100%; }
    &:nth-child(4) {
      max-width: 100%; }
    & + & {
      margin-top: 28px; } }

  .order {
    .consultation__text {
      font-size: 22px;
      line-height: 28px; } }
  .services {
    padding: 25px 0 0; }

  .sysadmin-offer__content-text {
    font-size: 26px;
    line-height: 33px;
    margin-bottom: 24px; }

  .sysadmin-offer__content-title {
    margin-bottom: 20px; }

  .sysadmin-offer__content-subtext {
    font-size: 20px;
    line-height: 26px; }

  .sysadmin-offer {
    padding-top: 25px; }

  .sysadmin__block-info {
    margin-bottom: 25px;
    .column__title {
      margin-bottom: 20px; }
    &:last-child {
      margin-bottom: 0; } }

  .sysadmin-thesis__list {
    display: block; }

  .sysadmin-thesis {
    padding-top: 25px; }

  .sysadmin__list-item:last-child {
    margin-bottom: 25px; }

  .sysadmin-sertification__content {
    margin-left: 0; }

  .sysadmin-sertification__title {
    font-size: 24px;
    line-height: 28px; }
  .sysadmin-sertification__text {
    font-size: 17px;
    line-height: 21px; }

  .lead {
    margin: 20px 0 30px; }

  .voted__comment {
    margin-top: 20px; }

  .calc__item,
  .calc__item-input {
    display: block; }

  .calc__item-input {
    margin-top: 20px;
    margin-left: 40px; }
  .calc__item > .calc__item-text {
    margin-left: 40px; }

  .title-color-tv {
    text-align: center; }

  .payment__form-item {
    display: block; }

  .payment__form-item-text {
    margin-bottom: 20px; }

  .options-payment__form2 {
    text-align: center; }

  .options-payment__select {
    margin: 0 auto; }

  .site-error__title {
    font-size: 200px;
    line-height: 200px; }

  .site-error__sub-title {
    font-size: 26px;
    line-height: 32px; }

  .cytys__preview {
    padding: 50px 0; }

  .citys__preview-title {
    font-size: 28px;
    line-height: 35px; }

  .citys__preview-text {
    font-size: 21px;
    line-height: 31px; }

  .hashtek__text {
    font-size: 28px;
    line-height: 32px; }

  .confirm {
    h1 {
      font-size: 32px;
      margin-top: 36px; }

    h2 {
      font-size: 24px; } }

  #confirmForm .select2 {
    width: 260px !important; }

  #confirmForm {
    padding-bottom: 50px;

    .calculate__form-position {
      display: flex; } }

  .confirm-order {
    padding: 50px 0;

    h1 {
      font-size: 32px; }

    h2 {
      font-size: 32px; }

    .calculate__list-item {
      margin-top: 30px; } }

  #btnYesConfirmOrder {
    margin-top: 30px; }

  .confirm-question {
    font-size: 16px;
    margin: 5px 4px;
    padding: 4px 11px; }

  .confirm-textarea {
    margin-top: 40px; }

  .confirm-questions_bottom {
    font-size: 18px; }

  .product-slider-item-wrapper {
    width: 300px;
    height: 400px; }

  .block1-list {
    li {
      &:first-child {
        &:after {
          display: block;
          margin-top: 15px;
          width: 65%; } }

      &:last-child {
        &:after {
          display: block;
          margin-top: 15px;
          width: 90%;
          margin-left: 0; } } } }

  .block1-list {
    margin: 43px 0; }

  .product-view-block2 {
    background-image: none;
    height: auto;
    padding: 30px 0; }

  .block3-img {
    height: 142px; }

  .product-view-block4 {
    background-image: none;
    padding: 34px 0; }

  .products-index-block-h4, .products-index-sliders-h4 {
    font-size: 32px; }

  .products-index-block-text, .products-index-sliders-text {
    font-size: 20px;
    line-height: 30px; }

  .product-modal-h3 {
    font-size: 25px; }

  #productModal {
    .modal-content {
      padding: 20px 8px; } }

  .productInput {
    width: 100%;
    font-size: 14px;
    padding: 7px 10px; }

  .product-modal-h3 {
    margin-bottom: 25px; }

  .product-modal-submit {
    font-size: 14px;
    padding: 7px 15px;
    margin-top: 20px; }

  .product-success-modal-list {
    li {
      font-size: 17px;
      margin-top: 8px; } }

  #productSuccessModal {
    .modal-content {
      padding-bottom: 20px; } }

  .home2-list {
    display: block;
    li {
      width: 100%; } }

  .home3-text {
    font-size: 24px; }

  .home6-list {
    display: block; }

  .home6-item {
    width: 100%;
    height: auto;
    min-height: 50px; }

  .home-view {
    .tariff-select-wrapper {
      padding-top: 25px; } }

  .home_bottom {
    padding: 70px 0; } }

@media only screen and (max-width : 480px) {
  .info__title {
    text-align: left; }

  .info__text {
    padding: 0; }

  .dropdown-menu__stock {
    min-width: unset; }

  .services-content__title {
    text-align: center; }

  .application-capability {
    justify-content: flex-start; }

  .colocation-info-provide__list1 {}
  //.colocation-info-provide__list2
  //padding-left: 20px

  .colocation-info__subtitle {
    font-size: 28px; }

  .colocation-info__text-block {
    font-size: 18px; }

  .title-color-tv {
    text-align: left; }

  .block-info__title-tv {
    text-align: left; }

  .block-info__title {
    text-align: left; }

  .column__img {
    margin-left: 0; }

  .column__title {
    text-align: left; }

  .column__text {
    text-align: left; }

  .phone-support__text {
    text-align: left; }

  .services-content .middle__text {
    text-align: left; }

  //.middle__title
  //  text-align: left

  .services__text {
 }    //text-align: left

  .middle-line {
    display: block;
    text-align: center; }

  .middle-line__wrapper-left {
    width: 100%;
    display: block;
    padding-bottom: 0; }

  .middle-line__promotion {
    display: block;
    margin-top: 20px;
    padding-left: 0;
    &:before {
      display: none; } }

  .logo {
    display: block;
    margin: 0 auto; }

  .middle-line__email {
    margin-right: 0; }

  .middle-line__tel {
    margin-right: 0; }

  .middle-line__tel-dropdown {
    margin: auto;
    top: 40px;
    left: 0px;
    right: 0; }

  .middle-line__telegram {
    margin-left: 0; }

  .right-menu {
    width: 100%;
    border-radius: 0; }

  .promotion-slider__type1 {
    .promotion__content-title {
      margin-bottom: 37px; }

    .promotion__content-btn {
      margin-top: 44px; } }

  .promotion__content-title {
    font-size: 32px;
    line-height: 39px; }

  .options__item {
    padding-left: 10px;
    padding-right: 10px; }

  .router-content {
    margin-bottom: 70px; }

  .router__image {
    display: none; }

  .footer-link {
    display: block;
    text-align: center; }

  .speedT-pt {
    display: block;
    padding-top: 20px; }

  .link_tp {
    padding-top: 20px; }

  .center_link {
    margin: 0 auto; }

  .services-card__item {
    width: calc(100% - 20px); }

  .services-card {
    justify-content: center; }

  //.info__title
  //  font-size: 38px

  .HowConnect-content__title {
    font-size: 38px; }

  .howConnect__title--center {
    text-align: left; }

  .howConnect-feedback__title {
    text-align: left; }

  .howConnect-feedback--center {
    text-align: left; }

  .router__title {
    text-align: left; }

  .router__text {
    text-align: left; }

  .mobile .mobile-content {
    text-align: left; }

  .mobile-content {
    text-align: left; }

  .news-slider .slick-dots {
    bottom: -5px; }
  .offer {
    padding-bottom: 10px;
    padding-top: 30px; }

  .services-card,
  .router-content,
  .phone-info__title {
    margin-bottom: 25px;
    text-align: left; }

  .services-plus__title {
    margin-bottom: 20px; }

  .rate-content__title {
    font-size: 38px; }

  .services-plus__title-business {
    margin-bottom: 25px; }
  .capability-content {
    margin-right: 0; }

  .capability-img {
    display: none; }

  .application-about {
    display: block; }

  .about-text {
    margin-bottom: 50px;
    max-width: 100%; }

  .about-link {
    display: flex;
    justify-content: center;
    .btn__link {
      margin-left: 7px;
      & + .btn__link {
        margin-top: 0;
        margin-left: 18px; } } }

  .HowConnect-content__list {
    padding-left: 0px; }

  .HowConnect-feedback__title {
    font-size: 24px; }

  .HowConnect-feedback__text {
    font-size: 24px; }

  .feedback-numb {
    margin: 0 10px; }

  .chooseTariffs {
    text-align: center; }

  .chooseTariffs__business {
    padding: 20px 0;
    background-position: -480px; }

  .chooseTariffs__business-title {
    font-size: 24px;
    line-height: 38px;
    text-align: left; }


  .blur {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(6px); }

  .rate__main-title {
    max-width: 100%;
    text-align: left;
    margin-bottom: 11px; }

  .rate__main-text {
    text-align: left; }

  .button-wrap {
    text-align: center; }

  .mainCards {
    padding: 15px 0; }
  .cards-view_content-title {
    font-size: 36px;
    line-height: 48px; }

  .cards-list {
    margin: 0; }

  .tariff-tabs > .nav-tabs > li {
    width: calc(100%/2 - 30px); }

  .feedback-numb {
    display: block;
    margin: 10px 0; }

  .block-info-video__title {
    font-size: 26px;
    line-height: 32px; }

  .rate__text {
    font-size: 20px; }

  .colocation-info-services__title-bus {
    text-align: left; }

  .colocation-info-services__item {
    text-align: left; }

  .calculate__form-title {
    font-size: 28px; }

  .calculate__form-position {
    display: block; }

  .calculate__list-item input[type="number"] {
    margin-top: 20px;
    display: block; }

  .consultation__title-bus {
    text-align: center; }

  .consultation__title-home {
    text-align: center; }

  .offer__ourClients-back {
    background-size: cover;
    height: 300px; }

  .colocation-info-param__inner {
    padding-left: 25px; }

  .phone-tariff__card-item {
    flex-basis: 100%;
    padding: 0 30px; }

  .tv__speak-list-item {
    margin-right: 0; }
  .phone__view > .tariff-tabs > .nav-tabs > li {
    width: calc(100% - 30px); }

  .tv__view > .tariff-tabs > .nav-tabs > li {
    width: calc(100% - 30px); }

  .tv__view > .tariff-tabs > .nav-tabs,
  .phone__view > .tariff-tabs > .nav-tabs {
    max-width: 350px; }

  .tv__header-inner {
    text-align: left; }

  .tv__header-title {
    text-align: left; }

  .btn__tv {
    display: block;
    margin: auto; }

  .settings-tv__main-title,
  .settings-tv__main-subtext {
    width: 100%; }

  .settings-tv__info {
    margin-left: 0; }

  .vols__about-item-text {
    font-size: 22px;
    line-height: 30px; }

  .vlan__about-inner,
  .vlan__about-list-title {
    font-size: 22px;
    line-height: 30px; }

  .thesis {
    padding: 25px 0; }

  .block-info__column {
    padding: 0; }

  .order {
    .consultation__text {
      font-size: 17px;
      line-height: 21px; }

    .phone-support__numb {
      font-size: 22px; }
    .consultation__subtext {
      font-size: 17px;
      line-height: 21px; } }

  .sysadmin-offer__content-text {
    font-size: 24px;
    line-height: 30px; }

  .sysadmin-offer__content-subtext {
    font-size: 17px;
    line-height: 21px; }

  .sysadmin-thesis__title {
    text-align: left; }

  .sysadmin-about__title {
    text-align: left; }

  .sysadmin-about__text {
    text-align: left; }

  .sysadmin__list-title {
    font-size: 24px;
    line-height: 28px; }

  .sysadmin-view__title {
    font-size: 28px;
    line-height: 36px; }

  .sysadmin__block-info .column__img {
    margin-left: 0; }

  .calc__item-text {
    font-size: 19px;
    line-height: 24px; }

  .calc__item>.calc__item-text {
    margin-left: 0; }

  .calc__item>.calc__item-input {
    margin-left: 0; }

  .about-link {
    .btn__link {
      margin: 0; } }

  .modal-dialog {
    margin: auto; }

  .modal-content {
    margin: 0 5px; }

  .site-error__title {
    font-size: 150px;
    line-height: 200px; }

  .site-error__sub-title {
    font-size: 21px; }

  .site-error__text {
    font-size: 17px;
    line-height: 21px; }

  .SLA {
    padding: 50px 0; }

  .SLA__inner {
    padding: 0 15px;
 }    //text-align: left

  .SLA__title {
    text-align: left; }

  .SLA__text {
    text-align: left;
    font-size: 18px;
    line-height: 28px; }

  .product-view-img img {
    max-width: 90%; }

  .product-price, .product-view-top h1, .block5-h4, .block1-h4, .block2-h4, .block3-h4, .block4-h4, .product-form-h4, .product-form-phone, .product-view-slider-h4 {
    font-size: 32px; }

  .product-price {
    margin-right: 10px;
    padding: 20px 0; }

  .product-btn {
    margin-left: 0;
    padding: 9px 20px; }

  .product-price-block {
    margin-bottom: 30px; }

  .product-list-slider {
    padding: 0; }

  .product-list-slider__item {
    height: 430px; }

  .product-list-slider .slick-dots {
    margin-top: 12px; }

  .product-form {
    padding: 30px; }

  .product-form-input {
    font-size: 14px;
    padding: 11px 16px; }

  .home1-text, .home2-h2, .home3-h2, .home4-h2, .home5-h2, .home6-h2, .home7-h2, .home-form-h2, .tariff-list-title_new, .home-view .connect-h3, .home_bottom-h3 {
    font-size: 32px;
    line-height: 42px; }

  .home1, .home2, .home3, .home4, .home5 {
    padding: 21px 0; }

  .home2-list li {
    font-size: 22px;
    margin-top: 20px; }

  .home3-text, .home5-question, .home5-number {
    font-size: 22px;
    line-height: 32px; }

  .home3 {
    padding-bottom: 65px; }

  .home-form {
    padding: 30px 0; }

  #homeForm {
    padding: 40px 20px; }

  .home5-list {
    margin-top: 26px; }

  .home6-list {
    margin: 16px; }

  .home5-list {
    margin-bottom: 15px; }

  .pay-h1 {
    width: 100%;
    max-width: none; }

  .pay-front, .pay-back {
    &:after {
      display: none; } }

  .pay-wrapper {
    margin: 60px 0; }

  .wrapper-pay-btns {
    width: 100%; }

  .pay-btn-back, .pay-btn-pay {
    font-size: 14px;
    width: 105px; } }


@media only screen and (max-width : 320px) {
  .mobile-content__link {
    font-size: 19px; } }
