@import "bourbon";
@import "fonts";
@import "vars";
@import "payment";





* {

  font-family: $default_font; }
label {
  font-weight: inherit; }
html {
  height: 100%; }
body {
  //display: none
  font-size: 17px;
  color: white;
  line-height: 21px;
  font-weight: 500;
  height: 100%; }

button {
  padding: 0;
  border: none;
  font: inherit;
  cursor: pointer;
  &:focus {
    outline: none; } }

input, select {
  border: none;
  margin: 0;
  outline: none; }


input[type=file]:focus,
input[type=checkbox]:focus,
input[type=radio]:focus {
  outline: none; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

h5 {
  span {
    display: inline-block;
    text-align: center; } }
img {
  vertical-align: baseline; }

input[type=number] {
  -moz-appearance: textfield; }

input[type=checkbox],
input[type=radio] {
  margin: 0; }
h1, h2, h3, h4, h5, h6 {
  margin: 0; }
* {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline; }

ul {
  margin: 0;
  padding: 0;
  list-style: none; }
a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    color: inherit; }
  &:focus {
    outline: none;
    text-decoration: none;
    color: inherit; }
  &:active {
    color: inherit;
    text-decoration: none; } }

header {
  background: #1F2431; }

.wrap {
  display: flex;
  flex-direction: column;
  height: 100%; }

main {
  flex: 1 0 auto; }

footer {
  flex: 0 0 auto; }

.button {
  min-width: 120px;
  padding: 12px 22px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 700;
  transition: all .3s ease-in;
  cursor: pointer;
  display: block;
  align-self: center;
  justify-content: center;
  color: #ffffff; }

.button-primary {
  background-color: #315EFB;
  &:focus {
    background-color: #2997FF; }
  &[disabled] {
    background-color: #62687F;
    cursor: not-allowed; } }



.button-secondary {
  background-color: #000000;

  &:hover {
    background-color: #343b4c; }
  &:focus {
    background-color: #343B4C; }
  &[disabled] {
    background-color: #62687F;
    cursor: not-allowed; } }


.alert-line {
  position: relative;
  padding: 7px;
  text-align: center;
  background: $blueToPurple; }
.alert-line__text {
  font-size: 14px;
  line-height: 17px;
  padding: 0px 70px; }

.alert__close {
  position: absolute;
  right: 45px;
  top: 50%;
  width: 15px;
  height: 15px;
  background: none;
  transform: translateY(-50%); }

.alert__close:before, .alert__close:after {
  position: absolute;
  top: 0;
  right: 7px;
  content: ' ';
  height: 15px;
  width: 2px;
  background-color: #fff; }

.alert__close:before {
  transform: rotate(45deg); }

.alert__close:after {
  transform: rotate(-45deg); }
.top-line__headerTypesTwo {
  .top-line {
    padding: 25px 0; } }

.top-line {
  border-bottom: 1px solid #757575; }

.top-line-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.top-line__left {
  display: flex;
  align-items: flex-end; }

.top-line__left-lang {
  color: $secondary_color;
  margin-right: 27px;
  font-weight: 700;
  padding-top: 2px;
  transition: all 0.3s ease-in-out;
  &:hover {
    color: #EEF2F4; } }
// Choose language =========
.textTransform {
  text-transform: uppercase; }

.dropdown-menu {
  left: -10px;
  margin-top: 10px;
  min-width: 75px;
  li {
    a {
      padding-left: 12px; } } }

// ====================
.top-line__left-langs {
  display: flex;
  align-items: baseline; }

.top-line__right-stock {
  position: relative; }

.mobile-stock {
  position: relative;
  display: none;
  z-index: 100; }

.right-stock__button {
  display: flex;
  align-items: baseline;
  cursor: pointer; }

.stock__main {
  background-image: linear-gradient(90deg,#ff6a64,#ff4bc8);
  -webkit-background-clip: text;
  color: transparent;
  margin-right: 5px;
  transition: all 0.4s ease-in-out;
  &:hover {
    background-image: linear-gradient(90deg,#ff6a64,#ff4bc8);
    -webkit-background-clip: text;
    color: transparent; } }

.stock-dots {
  path {
    &:nth-of-type(1) {
      fill: #FF688B; }
    &:nth-of-type(2) {
      fill: #FF5A96; }
    &:nth-of-type(3) {
      fill: #ff4EBE; } } }


// Choose stock price =========
.dropdown-menu__stock {
  display: none;
  position: absolute;
  background-color: #343b4c;
  min-width: 270px;
  padding: 26px 30px;
  border-radius: 12px;
  z-index: 1000;
  left: -10px;
  margin-top: 10px; }
.open {
  display: block;

  & li {
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0; } } }

.stock__main {
  &:focus,
  &:active {
    background-image: linear-gradient(90deg,#de92a7,#892fc5);
    -webkit-background-clip: text;
    color: transparent; } }

.dropdown-menu__stock-link {
  color: #8D96B2;
  transition: all 0.2s ease-in-out; }

.top-line__menu-item {
  display: inline-block;
  margin: 0 19px; }

.top-line__menu-link {
  position: relative;
  display: inline-block;
  padding: 24px 0;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 4px;
    transition: all 0.3s ease-in-out; }
  &:hover {
    -webkit-background-clip: text;
    color: transparent;
    transition: all .3s ease-in; }
  &:hover:after {
    opacity: 1; } }

.link__blue {
  color: #315EFB;
  &:hover {
    color: #315EFB;
    text-decoration: underline; } }

// class ACTEVIE FOR TYPE SITE
.top-line__menu-link.active.top__link-Home {
  background-image: $orangeToPink;
  -webkit-background-clip: text;
  color: transparent;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 1;
    width: 100%;
    height: 4px; } }

.top-line__menu-link.active.top__link-Business {
  background-image: $blueToPurple;
  -webkit-background-clip: text;
  color: transparent;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 1;
    width: 100%;
    height: 4px; } }

.top-line__menu-link.active.top__link-Pay {
  background: #cdf564;
  -webkit-background-clip: text;
  color: transparent;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 1;
    width: 100%;
    height: 4px; } }

.top__link-Home {
  &:after {
    background-image: $orangeToPink; }
  &:hover {
    background-image: $orangeToPink; } }

.top__link-Business {
  &:after {
    background-image: $blueToPurple; }
  &:hover {
    background-image: $blueToPurple; } }

.top__link-Pay {
  &:after {
    background-image: $yellow; }
  &:hover {
    background-image: $yellow; } }

.top__link-Magazine {
  &:after {
    background-color: #1ED760; }
  &:hover {
    color: #1ED760; } }

.more {
  position: relative;
  color: #8D96B2;
  cursor: pointer;
  display: inline-block;

  &:hover {
    .more__dropdown {
      display: block; } } }

.more__text {
  transition: all .3s ease-in;
  border: 2px solid #8D96B2;
  color: #8D96B2;
  width: 35px;
  height: 35px;
  border-radius: 35px;
  padding-top: 5px;
  &:hover {
    color: #ffffff;
    border-color: #fff;
    .dots {
      fill: #ffffff; } } }


.more__dropdown {
  display: none;
  position: absolute;
  background-color: #343B4C;
  min-width: 280px;
  padding: 26px 39px;
  border-radius: 12px;
  z-index: 10;
  &-item {
    display: block;
    transition: all .3s ease-in;
    &:hover {
      color: #ffffff; }
    & + .more__dropdown-item {
      margin-top: 5px; } } }

.dots {
  vertical-align: middle;
  margin-left: 5px;
  width: 22px;
  height: 6px;
  fill: $secondary_color;
  transition: all .3s ease-in; }

.tools {
  &:focus {
    color: inherit; }
  &:hover {
    color: #ffffff;
    .personal__icon {
      stroke: #ffffff; } } }

.tools__item {
  display: inline-block;
  vertical-align: middle;
  line-height: 25px;
  color: $secondary_color;
  transition: all .3s ease-in; }


.personal__icon {
  width: 21px;
  height: 27px;
  stroke: $secondary_color;
  fill: transparent;
  vertical-align: bottom;
  transition: all .3s ease-in; }


// SWIPE RIGHT MENU START
.menu__bg {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 500;
  background-color: rgba(0, 0, 0, 0.3); }

.burger {
  display: none;
  cursor: pointer; }

.burger-item {
  width: 25px;
  height: 3px;
  background-color: #ffffff;
  margin: 5px; }

.right-menu__close {
  padding: 30px;
  position: absolute;
  top: 0; }

.right-menu {
  display: none;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  width: 330px;
  background-color: #1F2431;
  transform: translateX(105%);
  transition: transform .5s ease-in;
  text-align: left;
  padding-top: 70px; }

.right-menu__wrap {
  height: 100%;
  overflow-y: auto;
  padding: 20px 30px 75px;
  position: relative; }

.right-menu__btn {
  width: 21px;
  height: 21px;
  background: none;
  border: none;
  background-image: url('/img/icons/CloseMenu.svg'); }

.right-menu__close {
  text-align: left; }


.right-menu__category {
  font-size: 21px;
  line-height: 26px;
  margin: 0 0 36px; }

.category__item {
  transition: all .2s ease-in; }

.category__item + .category__item {
  margin-top: 18px; }

.right-menu__col-home {
  &:hover {
    background-image: $orangeToPink;
    -webkit-background-clip: text;
    color: transparent; } }

.right-menu__col-business {
  &:hover {
    background-image: $blueToPurple;
    -webkit-background-clip: text;
    color: transparent; } }

.right-menu__col-magazine {
  &:hover {
    color: #1ED760; } }

.right-menu__col-other {
  color: $secondary_color;
  &:hover {
    color: #ffffff; } }

.right-line__link {
  display: flex;
  font-size: 17px;
  line-height: 21px;
  &:hover {
    .right-line__link-text {
      color: #ffffff; }
    .right-line__icon {
      fill: white; } } }

.right-menu__list-item + .right-menu__list-item {
  margin-top: 25px; }

.right-line__icon {
  transition: all .2s ease-in;
  fill: $secondary_color;
  width: 25px;
  height: 21px;
  margin-right: 18px; }

.right-line__link-text {
  transition: all .2s ease-in;
  color: $secondary_color; }



// SWIPE RIGHT MENU END

.middle-line {
  display: flex;
  justify-content: space-between;
  padding: 48px 15px 47px 15px;
  &__wrapper-left {
    width: 49%;
    display: flex;
    align-items: center; }
  &__wrapper-right {
    display: flex;
    align-items: center; } }

.logo {
  margin-right: 15px;
  max-width: 254px;
  width: 100%;
  height: 46px; }


.middle-line__email {
  line-height: 24px;
  color: $secondary_color;
  margin-right: 19px;
  transition: all .2s ease-in;
  &:hover {
    color: $secondary_color;
    text-decoration: underline; } }

.middle-line__phones {
  position: relative;
  margin-right: 70px;
  cursor: pointer;
  &:hover {
    .middle-line__tel-dropdown {
      display: block; } } }

.middle-line__tel-main {
  display: flex;
  align-items: baseline;
  justify-content: center; }

.middle-line__tel-dropdown {
  display: none;
  position: absolute;
  background-color: #343b4c;
  border-radius: 18px;
  padding: 23px;
  width: 230px;
  z-index: 11;
  margin-left: -24px; }

.middle-line__tel-low {
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: -0.011em;
  &:hover {
    text-decoration: underline; } }

.middle-line__tel-low+.middle-line__tel-low {
  margin-top: 13px;
  display: inline-block; }

.middle-line__tel {
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  background-image: $orangeToPink;
  -webkit-background-clip: text;
  color: transparent;
  cursor: pointer;
  &:hover {
    color: transparent; } }

.dots-phone {
  path {
    &:nth-of-type(1) {
      fill: #FF688B; }
    &:nth-of-type(2) {
      fill: #FF5A96; }
    &:nth-of-type(3) {
      fill: #ff4EBE; } } }

.telegMarg {
  padding-right: 3px; }

.bottom-line-fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5000;
  padding: 5px 0;
  &:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background-color: #1f2431;
    width: 100%;
    height: 100%;
    opacity: 0.9; } }

.bottom-line__list {

  padding: 10px 0 11px;
  display: flex;
  justify-content: center; }


.bottom-line__link {
  display: inline-block;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  &:hover {
    .bottom-line__link-text {
      color: #ffffff; }
    .bottom-line__icon {
      fill: white; } } }

.bottom-line__link.active {
    .bottom-line__link-text {
      color: #ffffff; }
    .bottom-line__icon {
      fill: white; } }

.bottom-line__list-item+.bottom-line__list-item {
  margin-left: 38px; }

.bottom-line__icon {
  transition: all .2s ease-in;
  fill: $secondary_color;
  width: 37px;
  height: 27px;
  margin-bottom: 10px; }

.bottom-line__link-text {
  transition: all .2s ease-in;
  display: block;
  font-size: 14px;
  color: $secondary_color; }



// SLIDER START

.main__background {
  position: relative; }
.promotion-slider__item {
  position: relative;
  min-height: 600px;
 }  //width: 195px

.promotion-slider__img {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  object-fit: cover; }

.slick-track {
  display: flex; }

.promotion-slider {
  position: relative;
  background-color: #1f2431;
  .slick-dots {
    position: absolute;
    bottom: 20px;
    left: 50%;
    right: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    .slick-active {
        button {
          background-color: rgba(255, 255, 255, 100); } }
    li {
      list-style: none;
      padding-right: 8px; }
    button {
      bottom: 42px;
      left: 50%;
      font-size: 0;
      width: 15px;
      height: 15px;
      background-color: rgba(255, 255, 255, 0);
      border: 2px #ffffff solid;
      border-radius: 50%;
      &:focus {
        outline: none; } } } }

.slick-slide:active {
  outline: none; }

.slick-active {
  outline: none; }

// SLIDER END

.promotion__content {
  padding-bottom: 85px;
  z-index: 1000;
  display: flex;
  height: 600px;
  flex-direction: column; }

.promotion__content-title {
  font-size: 48px;
  line-height: 58px;
  font-weight: 700;
  z-index: 1000;
  margin-bottom: 32px; }

.promotion__content-text {
  font-size: 32px;
  line-height: 39px;
  max-width: 760px; }

.promotion__content-btn {
  display: inline-block;
  color: #ffffff;
  margin-top: 43px;
  font-size: 21px;
  font-weight: 700;
  background: #000;
  padding: 13.5px 33.5px;
  border-radius: 25px;
  line-height: 26px;
  z-index: 1000; }


.promotion-slider__type1 {
  .promotion__content-text {
    margin: 0 auto; }
  .promotion__content {
    justify-content: flex-end; }
  .promotion__content-wrap {
    text-align: center; } }

.promotion-slider__type2 {
  .promotion__content-text {}
  margin: 0;
  .promotion__content {
    justify-content: center;
    text-align: left;
    .promotion__content-wrap {
      text-align: left; } }
  .promotion__content-title {
    max-width: 550px;
    width: 100%; }
  .promotion__content-text {
    max-width: 603px;
    width: 100%; } }

.wrapper {
  padding: 0 50px; }

.offer {
  background: #1F2431;
  padding: 40px 0 50px;
  &__check {
    max-width: 865px;
    margin: 0 auto;
    padding-bottom: 20px; } }

.offer__check-title {
  margin-bottom: 54px;
  span {
    color: #315EFB; } }

.offer__check-text {
  max-width: 477px;
  margin: 0 auto 42px;
  color: $secondary_color;
  font-size: 32px;
  line-height: 39px;
  text-align: center; }

.main-form {
  max-width: 350px;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  background: #343B4C;
  padding-top: 40px;
  padding-bottom: 20px;
  border-radius: 18px;
  align-self: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); }

.main-form__input {
  color: #ffffff;
  width: 254px;
  margin: 0 auto;
  display: block;
  background: rgba(#1F2431, 0.3);
  border-radius: 16px;
  padding: 14.5px 20px 14.5px 20px;
  line-height: 21px;
  border-style: none;
  &::placeholder {
    color: $secondary_color; } }


.offer-btn-m {
  margin: 40px auto 0; }

.main-form__input+.main-form__input {
  margin-top: 15px; }
//
.offer__ourClients-back {
  background-image: url("/img/back_case.png");
  background-repeat: repeat-x;
  //background-size: contain
  animation: background-logo 100s linear infinite;
  //animation-delay: 0s
  //animation-duration: 300s
  height: 675px; }


@keyframes background-logo {
  0% {
    background-position: 0; }
  //50%
  //  background-position: -50% 0%

  100% {
    background-position: 1833px; } }

.offer__case-slider-item {
  overflow: hidden; }

.card {
  &:nth-of-type(6n+1) {
    background-color: #2997FF; }
  &:nth-of-type(6n+2) {
    background-color: #315EFB; }
  &:nth-of-type(6n+3) {
    background-color: #BF5AF2; }
  &:nth-of-type(6n+4) {
    background-color: #FF375F; }
  &:nth-of-type(6n+5) {
    background-color: #FFA227; }
  &:nth-of-type(6n+6) {
    background-color: #1ED760; } }


.offer__case {
  padding-top: 38px; }

.offer__case-title {
  margin-bottom: 38px; }

.offer__case-text {
  text-align: center;
  color: $secondary_color;
  line-height: 22px;
  max-width: 337px;
  margin: 0 auto 46px;
  font-size: 18px; }

.card {
  position: relative;
  padding: 17px 19px;
  width: calc(100% / 3 - 23px);
  height: 200px;
  background: #315efb;
  border-radius: 18px;
  margin: 0 11px 25px; }

.card__top {
  font-weight: 700;
  line-height: 21px;
  display: flex;
  justify-content: space-between;
  &-wrap {
    font-size: 0;
    img {
      display: inline;
      & + img {
        margin-left: 7px; } } } }

.card__top-number {
  width: 45px;
  height: 45px;
  background: rgba(255, 255, 255, 0.25);
  border-radius: 50%;
  text-align: center;
  line-height: 47px; }

.card__btn-text {
  padding: 17px 19px;
  position: absolute;
  bottom: 0;
  left: 0;
  line-height: 21px;
  color: #ffffff;
  //overflow: hidden
  //text-overflow: ellipsis
  &:hover {
    text-decoration: underline; } }

.popup__content p {
  margin-bottom: 25px; }

.popup {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  background: rgba(#1F2431, .5); }



.popup__body {
  position: absolute;
  max-width: 900px;
  width: 100%;
  min-height: 400px;
  max-height: 500px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 25px;
  background-color: #1F2431;
  color: $secondary_color;
  border-radius: 36px;
  overflow-y: auto;
  overflow-x: hidden; }

.popup__content {
  padding-left: 40px;
  padding-top: 70px; }

.popup__close {
  position: absolute;
  top: 30px;
  right: 30px;
  width: 23px;
  height: 23px;
  background: none;
  cursor: pointer; }

.popup__close:before {
  content: '';
  background-color: $secondary_color;
  position: absolute;
  height: 2px;
  width: 12px;
  top: 11px;
  left: -4px;
  transform: rotate(-45deg); }

.popup__close:after {
  content: '';
  background-color: $secondary_color;
  position: absolute;
  height: 2px;
  width: 12px;
  top: 11px;
  left: -4px;
  transform: rotate(45deg); }

.options {
  background: #EEF2F4;
  padding: 52px 0 15px; }

.options__item {
  margin: 0 auto;
  max-width: 600px;
  width: 100%;
  background-image: linear-gradient(359.61deg, rgba(169, 201, 255, 0.65) 0.34%, rgba(255, 187, 236, 0.65) 99.68%);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 18px; }

.options-connect {
  margin-bottom: 50px;
  padding: 35px 0 25px 0; }

.options-connect__title-wrapper {
  text-align: center; }

.option-title {
  position: relative;
  font-weight: 700;
  color: #000000;
  font-size: 17px;
  line-height: 30px;
  &:before {
    content: '';
    color: #1d1d1f;
    height: 23px;
    width: 23px;
    position: absolute; } }

.options-connect__title {
  display: inline-block;
  padding-left: 30px;
  margin-bottom: 36px;
  &:before {
    background: url("../img/options_image/options_title_left.svg") no-repeat;
    bottom: 3px;
    left: 2px; } }

.options-connect__select {
  max-width: 300px;
  width: 100%;
  margin: 0 auto 40px auto;
  text-align: left;
  & > .select2-container--krajee .select2-selection {
    background-color: rgba(#ffffff, .35); } }


.options-payment__select {
  max-width: 280px;
  width: 100%;
  //margin: 0 auto 40px auto
  text-align: left;
  & > .select2-container--krajee .select2-selection {
    background-color: rgba(#ffffff, .35); } }

.options-payment__btn-group {
  margin-top: 35px;
  display: flex;
  justify-content: center;
  button {
    margin: 0 15px; } }
.options-connect__select .select2-container--krajee .select2-dropdown {
  border: none; }

.options-connect__checkbox {
  padding: 0 44px;
  margin-bottom: 40px; }

.options-connect__checkbox-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  & + & {
    margin-top: 15px; } }


.options-connect__select-checkbox {
  max-width: 265px;
  width: 100%;
  text-align: left;
  & > .select2-container--krajee .select2-selection {
    background-color: rgba(#ffffff, .35); } }

.form-input {
  background: rgba(#ffffff, .3);
  border-radius: 16px;
  color: #1D1D1F;
  &::placeholder {
    color: #62687F; } }

.checkbox-input {
  max-width: 265px;
  width: 100%;
  padding: 14px 20px 14px 20px; }

.options-connect-input {
  max-width: 300px;
  width: 100%;
  padding: 15px 33px;
  & + .options-connect-input {
    margin-top: 15px; } }

// SWITCH START

.options-connect__switch {
  margin-bottom: 9px;
  line-height: 30px;
  color: #1d1d1f; }

.options-connect__checkbox-input {
  position: relative;
  width: 42px;
  height: 23px;
  -webkit-appearance: none;
  outline: none;
  background: #CACDE1;
  border-radius: 20px;
  transition: 0.5s; }

.options-connect__checkbox-input:checked {
  background: $secondary_color; }

.options-connect__checkbox-input:before {
  content: "";
  position: absolute;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  background: #fff;
  top: 2px;
  left: 2px;
  transition: 0.5s; }


.options-connect__checkbox-input:checked:before {
  left: 21px; }


.options-connect__checkbox-input {
  vertical-align: middle; }


.options-connect__checkbox-label {
  vertical-align: middle;
  margin-left: 11px; }
// SWITCH END


.options-connect__form {
  text-align: center; }

.agree__wrapper {
  position: relative;
  max-width: 326px;
  width: 100%;
  margin: 15px auto 37px;
  padding-left: 20px;
  padding-top: 2px; }

.options-connect__form-agree {
  border: #8d96b2 5px solid;
  border-radius: 50%;
  position: absolute;
  left: 3px;
  top: 2px;
  opacity: 0;
  &:checked + .agree__label {
    &:after {
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 10px;
      background-color: #fff;
      position: absolute;
      top: 5px;
      left: 5px; } } }


.agree__label {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: #8D96B2;
  position: absolute;
  left: 0;
  top: 0; }

.options-connect__form-agree-label {
  display: inline-block;
  text-align: left;
  padding-left: 7px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  color: #000; }

.options-btn-m {
  margin: 0 auto; }

.options-payment {
  padding: 37px 0 25px; }

.options-payment2 {
  padding: 37px 0 25px;
  height: 100%; }

.options-payment__title {
  margin: 0 auto;
  max-width: 397px;
  width: 100%;
  padding-left: 43px;
  padding-bottom: 38px;
  &:before {
    background-image: url("../img/options_image/wallet.svg");
    background-repeat: no-repeat;
    top: 3px;
    left: 10px; }
  &:after {
    content: '';
    color: #1d1d1f;
    height: 23px;
    width: 92px;
    background-image: url("../img/options_image/Visa.svg");
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    right: 10px; } }

.requisites__title {
  margin: 0 auto;
  max-width: 397px;
  width: 100%;
  padding-left: 45px;
  padding-bottom: 38px;
  &:before {
    background-image: url("../img/options_image/union.svg");
    background-repeat: no-repeat;
    width: 27px;
    top: 3px;
    left: 10px; } }

.options-payment__form {
  position: relative;
  text-align: center;
  button {
    margin-top: 35px; } }

.form__error {
  position: absolute;
  top: 55px;
  left: 50%;
  color: red;
  font-size: 14px;
  transform: translateX(-50%); }
.payment__form-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 23px;
  .options-payment__input {
    margin-bottom: 0; }
  .payment__form-item-text {
    color: #000;
    span {
      display: block;
      color: #8D96B2; } }
  & + & {
    margin-top: 15px; } }

.options-payment__input {
  max-width: 280px;
  width: 100%;
  background: rgba(#ffffff, .3);
  padding: 15px 20px 15px 20px;
  border-radius: 16px;
  color: #1D1D1F;
  margin-bottom: 26px; }


.options-payment__form-text {
  margin: 0 auto;
  text-align: left;
  max-width: 313px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #000;
  &:first-child {
    margin-top: 26px; }
  a {
    text-decoration: underline;
    color: #315EFB; }
  & + & {
    margin-top: 20px; } }

// card rotate

.form-card {
  margin: 0 auto;
  position: relative;
  max-width: 600px;
  height: 354px;
  perspective: 1000px; }

.front, .back, .front_requisites, .back_requisites {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: 1s;
  backface-visibility: hidden; }


.back, .back_requisites {
  transform: rotateY(180deg); }

.select2-selection__rendered,
.select2-results__option {
  img {
    width: 50px;
    margin-right: 20px;
    vertical-align: middle; } }
.chooseTariffs {
  position: relative;
  background: url("../img/back.svg") #C6CAD8 no-repeat;
  background-position: center 100%;
  background-size: contain;
  padding: 100px 0 99px; }



.chooseTariffs__business {
  position: relative;
  background: url("../img/back_business.jpg") #C6CAD8 no-repeat;
  background-size: cover;
  background-position: center;
  padding: 135px 0 130px;
  text-align: center; }

.chooseTariffs__business-title {
  font-size: 36px;
  line-height: 44px;
  color: #000000;
  font-weight: 700;
  max-width: 515px;
  width: 100%;
  margin: 0 auto 24px; }

.rate {
  padding: 50px 0; }

.rate__main-title {
  background-image: $orangeToPink;
  -webkit-background-clip: text;
  color: transparent;
  margin-bottom: 26px;
  font-size: 36px;
  line-height: 44px;
  font-weight: 700;
  max-width: 375px;
  width: 100%; }

.rate__main-text {
  color: #62687F;
  font-size: 21px;
  line-height: 26px;
  margin-bottom: 36px; }

.rate-btn-m {
  display: inline-block; }

.rate-variables-business {
  display: none; }

.rate__title {
  color: #000;
  margin-bottom: 15px;
  span {
    background-image: $orangeToPink;
    -webkit-background-clip: text;
    color: transparent; } }

.rate__text {
  color: #62687F;
  text-align: center;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 10px; }

.rate__text-internet {
  margin-bottom: 30px; }

.rate__select {
  margin: 0 auto 5px auto;
  max-width: 280px;
  & .select2-container--krajee .select2-selection {
    border: 1px solid rgba(#8D96B2, .3);
    &:focus {
      border: 1px solid rgba(#8D96B2, .3); } } }

.post__data {
  margin-top: 35px; }

.rate-item {
  position: relative;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  border: 1px solid #C6CAD8;
  text-align: center;
  padding: 29px 0 28px 0;
  margin: 40px 25px 15px; }

.rate-item__home-color {
  background-image: linear-gradient(359.61deg,rgba(169,201,255,.65) .34%,rgba(255,187,236,.65) 99.68%); }

.rate-item__tv-color {
  background-image: linear-gradient(359.61deg,rgba(#343B4C, 1) 100%,rgba(#1F2431, 1) 1%); }

.tv_rate .slick-initialized .slick-slide {
  display: flex;
  flex-direction: column; }

.tv_rate .slick-slide {
  height: auto; }
.tv_rate   .rate-item__list {
  flex-grow: 1; }
.rate-item__business-color {
  background: linear-gradient(360deg, rgba(131, 106, 252, 0.7) 1.63%, rgba(52, 154, 255, 0.7) 101.05%), linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%); }

.rate-item__forNewClients {
  text-align: center;
  font-size: 14px;
  line-height: 17px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 160px;
  height: 23px;
  margin: 0 auto;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px; }

.forNewClients-color-home {
  background-color: #00D37F; }

.forNewClients-color-business {
  background-color: #BF5AF2; }

.hot__img {
  position: absolute;
  top: -35px;
  right: -12px;
  background: url("../img/rare_img/fire.svg");
  width: 40px;
  height: 60px; }

.rate-item__title {
  font-size: 26px;
  font-weight: 700;
  line-height: 29px;
  color: #000000;
  margin-bottom: 36px;
  span {
    font-size: 85px;
    line-height: 94px; } }

.rate-item__list {
  padding: 0 31px;
  margin-bottom: 22px;
  font-size: 17px;
  line-height: 19px;
  color: #000000; }

.rate-slider__business {
  outline: none;
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
  .slick-arrow {
    position: absolute;
    top: 50%;
    z-index: 10;
    font-size: 0;
    width: 50px;
    height: 50px; } }

.rate-slider__business .slick-arrow.slick-prev {
  left: -60px;
  background: url("../img/rare_img/left_arrow.svg"); }
.rate-slider__business .slick-arrow.slick-next {
  right: -60px;
  background: url("../img/rare_img/right_arrow.svg"); }

.rate-slider {
  outline: none;
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
  .slick-arrow {
    position: absolute;
    top: 50%;
    z-index: 10;
    font-size: 0;
    width: 50px;
    height: 50px; } }
.rate-slider .slick-arrow.slick-prev {
  left: -60px;
  background: url("../img/rare_img/left_arrow.svg"); }
.rate-slider .slick-arrow.slick-next {
  right: -60px;
  background: url("../img/rare_img/right_arrow.svg"); }


.rate-item__item + .rate-item__item {
  margin-top: 10px; }

.rate-item__item {
  a {
    text-decoration: underline;
    color: #315EFB; } }

.rate-item__price {
  color: #000000;
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  margin-bottom: 16px;
  span {
    font-size: 45px;
    line-height: 50px; } }

.text-color-bus {
  color: #ffffff; }

.rate-item__link {
  a {
    color: #000000; } }

.router {
  padding: 49px 0 0 0; }

.router-content {
  max-width: 538px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 39px; }

.router__title {
  color: #000000;
  margin-bottom: 26px; }

.router__text {
  font-size: 24px;
  line-height: 29px;
  color: #62687F;
  margin-bottom: 34px; }


.router__link {
  position: relative;
  color: #315EFB;
  font-size: 21px;
  line-height: 26px;
  &:after {
    padding-left: 5px;
    content: url("../img/router_image/Vector_link.svg");
    height: 13px;
    width: 9px; }
  &:hover {
    color: #315EFB;
    text-decoration: underline; }
  &:focus {
    text-decoration: none;
    color: #315EFB; } }

.router__image {
  margin: 0 auto;
  background: url("../img/router_image/router.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 100%;
  object-fit: cover;
  padding-bottom: 48%;
  height: 100%;
  width: 100%; }


.offer__ourClients-title {
  margin-bottom: 40px; }

.our-clients {
  background-color: #1F2431;
  padding-top: 38px;
  padding-bottom: 64px; }

.our-clients__title {
  color: #ffffff;
  margin-bottom: 38px; }

.our-clients__wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px 43px;
  justify-content: center;
  min-width: 0; }


.our-clients__wrapper.slick-initialized .slick-slide {
  display: flex; }

.our-clients__items-logo {
  display: flex;
  margin: 7px;
  border-radius: 8px;
  min-width: 0;
  width: calc(100%/5 - 17px);
  height: 195px;
  background-color: #343B4C;
  justify-content: center;
  padding: 28px;
  img {
    width: 100%; }
  &:hover {
    .our-clients__img {
      margin-top: -136px; } }

  .our-clients__img-wrap {
    width: 100%;
    max-height: 100%;
    overflow: hidden; } }

.our-clients__text {
  text-align: center;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  background-image: $orangeToPink;
  -webkit-background-clip: text;
  color: transparent; }

.mobile {
  padding: 77px 0 0;
  background: url("/img/backGround2.jpg"), #2eb4e6 no-repeat;
  background-size: cover;
  color: #000000; }

.mobile-vision {
  padding: 110px 0 0;
  color: #000000;

  background: url("/img/backGround3.jpg"), #2eb4e6 no-repeat;
  background-size: cover; }

.mobile-content__wrapper {
  position: relative; }

.mobile-content {
  max-width: 532px;
  width: 100%;
  padding-bottom: 110px; }

.mobile__title {
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  margin-bottom: 26.5px; }

.mobile__subtitle {
  font-weight: 500;
  font-size: 21px;
  line-height: 26px;
  color: #343B4C;
  margin-bottom: 26.5px; }

.mobile__text {
  max-width: 439px;
  width: 100%;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 26.5px; }

.mobile__link {
  display: flex;
  font-weight: 500;
  font-size: 21px;
  line-height: 15px;
  color: #000000;
  margin-bottom: 45px;
  &:hover {
    color: #315EFB;
    text-decoration: underline;
    .rightArr {
      fill: #315efb; } } }

.rightArr {
  fill: #000000;
  width: 9px;
  height: 16px;
  margin-left: 7px; }


.link__wrapper {
  display: flex; }


.btn__link {
  display: flex;
  justify-content: center;
  width: 141px;
  height: 42px;
  background:  #000000;
  border-radius: 8px;
  line-height: 17px;
  transition: all .2s ease-in;
  margin: 9px;
  img {
    width: 103px;
    height: 26px;
    margin: auto 0; }
  &:hover {
    background: #343B4C; } }

//
//.btn__link +.btn__link
//  margin-left: 18px

.mobile__image {
  position: absolute;
  bottom: 0;
  right: 0; }

.mobile .mobile-content {
  padding-bottom: 43px; }
.mobile-vision__image {
  position: absolute;
  bottom: 0;
  right: -80px; }

.news {
  padding-top: 60px;
  padding-bottom: 95px;
  background-color: #F0F0F0; }

.news-slider {
  position: relative;
  outline: none;
  .slick-track {
    padding: 10px 0; }
  .slick-arrow {
    position: absolute;
    top: 50%;
    z-index: 10;
    font-size: 0;
    width: 50px;
    height: 50px; }
  .slick-dots {
    position: absolute;
    bottom: -55px;
    left: 50%;
    right: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    .slick-active {
      button {
        background-color: #62687F; } }
    button {
      background-color: #F0F0F0;
      font-size: 0;
      width: 15px;
      height: 15px;
      border: 2px solid #62687F;
      border-radius: 50%; }
    li {
      padding-right: 8px;
      list-style: none; } } }

.news-slider .slick-arrow.slick-prev {
  left: -60px;
  background: url("../img/rare_img/left_arrow.svg"); }

.news-slider .slick-arrow.slick-next {
  right: -60px;
  background: url("../img/rare_img/right_arrow.svg"); }
.news-slider__item-img {
  width: 100%;
  margin-bottom: 15px; }

.news-slider__content {
  padding-left: 19px;
  padding-right: 19px;
  min-height: 170px; }

.mainCards {
  padding: 50px 0; }

.mainCards__item {
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); }

.news__card-item {
  margin: 0 15px 25px;
  color: #1D1D1F; }

.cards-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 25px 25px;
  &:after {
    content: "";
    flex: auto; } }

.card-item {
  width: calc(100% / 3);
  padding: 20px 15px;
  color: #1D1D1F; }

.news-slider__item-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 13px; }

.news-slider__item-link {
  font-size: 16px;
  line-height: 19px;
  &:hover {
    text-decoration: underline; } }

.news-slider__item-bottom-group {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 17px;
  left: 19px;
  right: 19px;
  line-height: 17px; }

.news-slider__item-date {
  font-size: 14px;
  color: $secondary_color; }

.social__wrapper {
  display: flex;
  gap: 8px; }

.footer {
  padding: 27px 0 29px;
  background: #1F2431; }

.footer__inner {
  display: flex;
  justify-content: space-between;
  color: $secondary_color;
  line-height: 21px;
  padding-bottom: 17px;
  border-bottom: 0.5px solid $secondary_color; }

.footer__title {
  font-weight: 700;
  font-size: 21px;
  line-height: 25px;
  margin-bottom: 9px; }

.company__color {
  color: #BF5AF2; }

.home__color {
  background-image: $orangeToPink;
  -webkit-background-clip: text;
  color: transparent; }

.home__pad {
  margin-right: 60px; }

.business__color {
  background-image: $blueToPurple;
  -webkit-background-clip: text;
  color: transparent; }

.footer__list-item + .footer__list-item {
  padding-top: 9.5px; }

.footer__list-item {
  transition: all .2s ease-in;
  &:hover {
    color: #ffffff; } }

.footer__accordion {
  display: none;
  color: $secondary_color;
  line-height: 21px;
  border-bottom: 0.5px solid #8D96B2;
  padding-bottom: 17px; }

.acc__title {
  position: relative;
  display: inline-block;
  font-weight: 700;
  font-size: 21px;
  line-height: 25px;
  margin-bottom: 9px;
  cursor: pointer;
  &:after {
    content: '';
    width: 10px;
    height: 15px;
    position: absolute;
    top: 5px;
    right: -23px;
    padding-left: 10px;
    color: white; } }

.acc_Company {
  color: #BF5AF2;
  &:after {
    background: url("../img/icon__footer/foot_arr_purpl.svg") no-repeat; } }

.acc__Home {
  background-image: $orangeToPink;
  -webkit-background-clip: text;
  color: transparent;
  &:after {
    background: url("../img/icon__footer/foot_arr_barb.svg") no-repeat; } }

.acc__Business {
  background-image: $blueToPurple;
  -webkit-background-clip: text;
  color: transparent;
  &:after {
    background: url("../img/icon__footer/foot_arr_blue.svg") no-repeat; } }

.accordion__list {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: .5s ease-in-out; }

.footer__accordion-item.active .accordion__list {
  height: 240px; }

.footer__accordion-item + .footer__accordion-item {
    margin-top: 30px; }

.footer-link__social-item {
  display: inline-block;
  background-color: #343B4C;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  & + .footer-link__social-item {
    margin-left: 7px; }
  &:hover {
    .footer-link__social-item__img {
      img {
        margin-top: -20px; } } } }

.footer-link {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 20px; }

.footer-link__social-item__img {
  width: 20px;
  height: 20px;
  overflow: hidden;
  margin: 10px auto;
  img {
    width: 100%; } }

.speedTest {
  margin-bottom: 15px;
  fill: $secondary_color;
  width: 130px;
  height: 16px;
  transition: all .2s ease-in;
  &:hover {
    fill: #C6CAD8; } }

.connection-check {
  background: #1F2431;
  padding: 39px 0 51px; }

.middle__title {
  display: block;
  text-align: center;
  font-size: 48px;
  line-height: 58px;
  font-weight: 700; }

.check-title {
  margin-bottom: 38px;
  background-image: $orangeToPink;
  -webkit-background-clip: text;
  color: transparent; }

.check-title__business {
  margin-bottom: 38px;
  background-image: $blueToPurple;
  -webkit-background-clip: text;
  color: transparent; }

.connection-check__form-inner {
  display: flex;
  align-items: center; }

.connection-check__form {
  max-width: 512px;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  background: #343B4C;
  padding: 40px;
  border-radius: 18px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); }

.connection-check__form-input {
  color: #ffffff;
  max-width: 280px;
  width: 100%;
  display: inline;
  background: rgba(#1F2431, 0.3);
  border-radius: 16px;
  padding: 14.5px 10px 14.5px 20px;
  line-height: 21px;
  border-style: none;
  &::placeholder {
    color: $secondary_color; } }


.connection-check-btn {
  margin-left: 40px; }

.info {
  background: #ffffff;
  padding: 40px 0 47px;
  color: #1D1D1F; }

.info__title {
  margin-bottom: 33px; }

.info__text {
  line-height: 21px;
  & + .info__text {
    margin-top: 25px; } }

.services {
  background: #1F2431;
  padding: 51px 0 50px; }

.services-content__title {
  margin-bottom: 22px;
  background-image: $orangeToPink;
  -webkit-background-clip: text;
  color: transparent; }

.services-content__title-business {
  margin-bottom: 22px;
  background-image: $blueToPurple;
  -webkit-background-clip: text;
  color: transparent; }

.services-content__title-tv {
  margin-bottom: 22px;
  background-image: $purpleToPink;
  -webkit-background-clip: text;
  color: transparent; }

.services__text {
  max-width: 604px;
  width: 100%;
  font-size: 24px;
  line-height: 29px;
  margin: 0 auto 35px;
  text-align: center; }

.services-card {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px 39px -10px; }

.services-card__item {
  position: relative;
  overflow: hidden;
  max-width: 350px;
  width: calc(100%/3 - 20px);
  border-radius: 19px;
  margin: 10px;
  transition: transform 1s ease;
  & img {
    height: 100%; }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 36.17%, rgba(0, 0, 0, 0.7) 100%); }
  &:hover {
    transform: scale(0.99); } }

.services-card__link {
  position: absolute;
  line-height: 21px;
  font-size: 1.8rem;
  bottom: 26px;
  left: 25px;
  right: 25px;
  z-index: 10;
  display: inline-block;
  &:hover {
    text-decoration: underline; } }

.services-plus__title {
  margin-bottom: 40px;
  background-image: $orangeToPink;
  -webkit-background-clip: text;
  color: transparent; }

.services-plus__title-business {
  margin-bottom: 40px;
  background-image: $blueToPurple;
  -webkit-background-clip: text;
  color: transparent; }

.services-plus__title-tv {
  margin-bottom: 40px;
  background-image: $purpleToPink;
  -webkit-background-clip: text;
  color: transparent; }

.application {
  background: center url("../img/backGround2.svg") no-repeat;
  background-size: cover;
  padding: 40px 0;
  color: #000000; }

.application-inner__wrapper {
  display: flex;
  justify-content: space-between; }

.application-capability {
  display: flex;
  align-items: center; }


.capability-content {
  max-width: 390px;
  width: 100%;
  margin-right: 33px; }

.capability-content__mainTitle {
  font-size: 28px;
  line-height: 34px;
  font-weight: 700;
  margin-bottom: 40px; }

.capability-content__block {
  margin-bottom: 29px; }

.capability-content__title {
  font-size: 21px;
  line-height: 26px;
  font-weight: 700;
  margin-bottom: 8px; }

.capability-content__text {
  font-size: 14px;
  line-height: 17px;
  color: #343B4C; }

.application-about {
  position: relative;
  margin: auto 0;
  max-width: 398px;
  padding-left: 20px; }

.about-text {
  display: block;
  font-size: 21px;
  line-height: 26px; }

.about-link {
  display: flex;
  position: absolute;
  bottom: -185px;
  left: 20px; }

.howConnect {
  background: #EEF2F4;
  color: #1D1D1F;
  padding: 39px 0 45px; }

.howConnect__title--center {
  margin-bottom: 33px;
  text-align: center; }

.howConnect__list {
  margin-bottom: 106px; }

.howConnect__list-item {
  line-height: 21px;
  & + .howConnect__list-item {
    margin-top: 20px; } }

.howConnect-feedback__title {
  font-weight: 700;
  margin-bottom: 19px;
  font-size: 26px;
  line-height: 32px; }

.howConnect-feedback__text {
  font-size: 26px;
  line-height: 32px; }

.howConnect-feedback--center {
  text-align: center; }

.howConnect__title--left {
  text-align: left;
  margin-bottom: 38px; }



.howConnect-feedback--left {
  text-align: left; }



.feedback-numb {
  font-weight: 700;
  background-image: $orangeToPink;
  -webkit-background-clip: text;
  color: transparent;
  margin: 0 20px;
  &:hover {
    background-image: $orangeToPink;
    -webkit-background-clip: text;
    color: transparent; }
  &:focus {
    background-image: $orangeToPink;
    -webkit-background-clip: text;
    color: transparent; } }

.feedback-numb__business {
  font-weight: 700;
  background-image: $blueToPurple;
  -webkit-background-clip: text;
  color: transparent;
  margin: 0 20px;
  &:hover {
    background-image: $blueToPurple;
    -webkit-background-clip: text;
    color: transparent; } }

.feedback-link {
  margin-left: 20px;
  color: #315EFB;
  &:hover {
    text-decoration: underline;
    color: #315EFB;
    .feedbckArr {
      fill: #315efb; } } }

.feedbckArr {
  fill: #315efb;
  width: 9px;
  height: 16px; }

.video-home {
  background-color: #EEF2F4; }

.block-info {
  color: #000000; }

.block-info__bus-internet {
  background-color: #EEF2F4;
  padding: 50px 0 20px; }

.block-info-video__title {
  background-color: white; }

.block-info__title {
  margin-bottom: 20px; }

.block-info__title-video {
  text-align: left; }

.block-info-video__1-row {
  padding: 39px 0 39px;
  background-color: #EEF2F4; }

.block-info-video__2-row {
  padding: 40px 0;
  background-color: white; }

.block-info__inner {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -25px; }

.block-info__column {
  flex: 0 1 50%;
  padding: 0 25px;
  margin-bottom: 50px;
  &:last-child {
    margin-bottom: 0; } }

.column__wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
.column__img {
  display: block;
  margin: 0 auto;
  margin-bottom: 18px; }

.column__title {
  font-size: 26px;
  line-height: 32px;
  font-weight: 700;
  margin-bottom: 30px;
  word-wrap: break-word; }

.info-business__column-title {
  font-size: 26px;
  line-height: 32px;
  background-image: $blueToPurple;
  -webkit-background-clip: text;
  color: transparent;
  margin-bottom: 20px; }


.info-business__list-item {
  position: relative;
  padding-left: 31px;
  & + .info-business__list-item {
    margin-top: 20px; }
  &:before {
    content: '';
    background: url("/img/tariff_icon.svg");
    width: 21px;
    height: 16px;
    position: absolute;
    top: 3px;
    left: 0; } }

.column__title-width {
  max-width: 310px; }
.column__text {
  color: #62687F; }


.info-business__list {
  max-width: 345px; }

.info-business__list-third {
  max-width: 310px; }
.block-info-video__title {
  font-size: 32px;
  line-height: 38px;
  padding: 43px 0 42px; }

.video-apply {
  color: #1D1D1F;
  padding: 40px 0;
  background-color: #EEF2F4; }

.video-apply__title {
  text-align: left;
  margin-bottom: 34px; }

.apply-text__wrap {
  margin-bottom: 42px; }

.video-apply__text + .video-apply__text {
  margin-top: 25px; }

.video-apply__link {
  display: block;
  text-align: center; }

.video-form-btn {
  display: flex;
  margin: 35px auto 0; }
.news__pagination {
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    li span {
      cursor: pointer;
      padding: 0;
      border: none; }
    li a {
      color: #000;
      background: none;
      border: none;
      padding: 0 5px;
      &:hover {
        color: #000; }
 } }      //margin: 0 5px
  .pagination .active a {
    color: #FF375F;
    cursor: pointer;
    &:hover {
      background-color: transparent;
      color: #FF375F; }
    &:focus {
      background-color: transparent; } }
  .pagination .next a,
  .pagination .prev a {
    background: url("/img/arrCard.svg") no-repeat;
    background-position-y: center;
    height: 20px; }
  .pagination .prev {
    transform: rotate(-180deg);
    margin-right: 10px; }
  .pagination .next {
    margin-left: 10px; } }

.cards-view_item {
  width: 100%; }

.cards-view_img {
  width: 100%; }

.content__wrap {
  padding: 0 200px; }

.cards-view {
  background: #ffffff; }

.cards-view_content {
  position: relative;
  padding-top: 80px; }

.cards-view_content-backLink-wrap {
  position: absolute;
  color: #62687F;
  z-index: 5; }

.cards-view_content-backLink {
  position: relative;
  line-height: 30px;
  font-size: 21px;
  color: #62687F;
  padding-left: 20px;
  &:before {
    content: '';
    background: url("../img/arrowLeft.svg") no-repeat;
    width: 10px;
    height: 16px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    fill: #00D37F;
    color: red; }
  &:hover {
    color: #8D96B2; }
  &:hover:before {
    background: url("../img/arrowLeftHover.svg") no-repeat; } }

.cards-view_content-title {
  color: #1D1D1F;
  font-size: 48px;
  line-height: 60px;
  max-width: 580px;
  margin-bottom: 18px;
  margin-top: -5px; }

.blog_content-title {
  margin-bottom: 9px; }

.cards-view_content-subtitle {
  font-size: 22px;
  line-height: 28px;
  color: #343B4C;
  margin-bottom: 44px; }

.cards-view_content-wrap {
  margin-bottom: 22px;
  display: flex;
  justify-content: space-between;
  line-height: 28px; }

.cards-view_content-date {
  color: $secondary_color;
  font-size: 14px;
  span {
    height: 5px;
    width: 5px;
    display: inline-block;
    border-radius: 50%;
    background-color: $secondary_color;
    margin: 0 5px 3px; } }

.cards-view_content-wrap-text {
  color: #1D1D1F;
  font-size: 21px;
  line-height: 26px;
  margin-bottom: 100px; }

.cards-view_content-item + .cards-view_content-item {
  margin-top: 40px;
  a {
    color: #315EFB;
    &:hover {
      text-decoration: underline; } } }

.cards-view_content-item-list {
  list-style: disc;
  padding-left: 30px; }

.cards-view_content-link {
  color: #315EFB;
  line-height: 21px;
  padding: 10px 0 11px;
  background: #EEF2F4; }

.cards-view_content-link-flex {
  display: flex;
  justify-content: space-between; }

.cards-view_nav-link {
  position: relative;
  padding: 0 15px;
  &:hover {
    text-decoration: underline; } }

.cards-view_content-link-prev:before,
.cards-view_content-link-next:after {
  content: '';
  height: 13px;
  width: 9px;
  position: absolute;
  top: 5px; }

.cards-view_content-link-prev:before {
  background: url("../img/router_image/Vector_link.svg") no-repeat;
  left: 0;
  transform: rotate(180deg); }

.cards-view_content-link-next {
  text-align: right;
  margin-left: auto;
  &:after {
    background: url("../img/router_image/Vector_link.svg") no-repeat;
    right: 0;
    right: 0; } }


.tariff__inner {
  padding-top: 50px; }

.tariff-business__tabs {
  padding-top: 50px; }

.business__tab-btn {
  background: none; }

.tariff-select {
  width: 280px;
  margin: 0 auto;
  & .select2-container--krajee .select2-selection {
    border: 1px solid rgba(#8D96B2, .3);
    &:focus {
      border: 1px solid rgba(#8D96B2, .3); } } }

#chooseLocation .select2-container--krajee .select2-dropdown {
  border: 1px solid rgba(#8D96B2, .3); }
// SELECT

.select2-container--krajee .select2-selection--single {
  height: auto;
  padding: 14px 10px 14px 20px;
  border-radius: 18px; }

.select2-container--krajee .select2-selection {
  font-size: 17px;
  line-height: 21px;
  background: rgba(#C6CAD8, .35);
  border: none;
  //border: 1px solid rgba(#8D96B2, .3)
  box-shadow: none;
  color: #1F2431;
  &:focus {
    //border-color: rgba(#8D96B2, .3)
    border: none;
    box-shadow: none; } }



.select2-container--krajee .select2-results__option--highlighted[aria-selected] {
  background-color: white;
  color: #1F2431;
  &:hover {
    background-color: rgba(#8D96B2, .3); } }

.select2-results__option {
  padding: 15px 15px 15px 20px; }

.select2-container--krajee .select2-selection--single .select2-selection__placeholder {
  color: rgba(#62687F, .35); }

.select2-container--krajee .select2-selection--single .select2-selection__rendered {
  color: #1F2431;
  padding-right: 20px; }

.select2-container--krajee .select2-results__option.select2-results__option--selected, .select2-container--krajee .select2-results__option[aria-selected=true] {
  color: #1F2431;
  background-color: rgba($secondary_color, .5); }

.select2-container--krajee.select2-container--open .select2-selection {

  box-shadow: none; }

.select2-container--krajee .select2-selection--single .select2-selection__arrow {
  border-left: none; }

.select2-container--krajee .select2-selection--single .select2-selection__arrow b {
  border: none;
  background: url(../img/selectArrow.svg) no-repeat;
  width: 15px;
  height: 10px;
  margin-top: 5px;
  margin-left: -19px; }

.select2-container--krajee.select2-container--open .select2-selection--single .select2-selection__arrow b {
  transform: rotate(180deg);
  margin-top: 3px;
  margin-left: -20px; }

.select2-container--krajee .select2-dropdown {
  border: none;
  box-shadow: none; }



.select2-dropdown {
  border-radius: 18px; }

.tariff-tabs {
  width: 100%;
  margin: 49px auto 0; }

.tariff-tabs > .nav-tabs {
  max-width: 1000px;
  margin: 0 auto;
  border: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  &:after {
    content: "";
    flex: auto; } }

.tariff-tabs > .nav-tabs > li {
  width: calc(100%/5 - 30px);
  margin: 0 15px 40px; }

.tariff-tabs > .nav-tabs > li > a {
  background: #ffffff;
  border: 2px solid #C6CAD8;
  border-radius: 12px;
  color: #000000;
  padding: 25px 0;
  text-align: center;
  font-size: 48px;
  font-weight: 700;
  &:hover {
    border-color: #C6CAD8;
    background-color: transparent; } }

.phone__view > .tariff-tabs > .nav-tabs > li {
  width: calc(100%/3 - 30px); }

.phone__view > .tariff-tabs > .nav-tabs > li > a {
  font-size: 32px;
  line-height: 35px; }

.tv__view > .tariff-tabs > .nav-tabs {}

.tv__view > .tariff-tabs > .nav-tabs > li {
  width: calc(100%/3 - 30px); }

.tv__view > .tariff-tabs > .nav-tabs > li > a {
  font-size: 32px;
  line-height: 35px; }

.tariff-tabs > .nav-tabs > li.active > a {
  color: #000000;
  background-color: #C6CAD8;
  border: 2px solid #C6CAD8;
  &:hover {
    background-color: #C6CAD8;
    color: #000000;
    border: 2px solid #C6CAD8; }
  &:focus {
    background-color: #C6CAD8;
    color: #000000;
    border: 2px solid #C6CAD8; } }

.tariff-tabs > .nav li a:focus {
  color: #000000;
  background: #ffffff; }




.tv-tariff__title {
  color: #000000;
  max-width: 762px;
  width: 100%;
  margin: 0 auto 32px;
  span {
    background-image: $purpleToPink;
    -webkit-background-clip: text;
    color: transparent; } }

.tariff-speed {
  text-align: center;
  margin-bottom: 30px; }

.tariff__label {
  font-size: 14px;
  line-height: 16px; }

.tariff-content {
  padding: 40px 10px; }

.tariff-content__item {
  position: relative;
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
  padding-left: 35px;
  & + .tariff-content__item {
    margin-top: 37px; }
  &:before {
    content: '';
    background: url("/img/tariff_icon.svg");
    width: 21px;
    height: 16px;
    position: absolute;
    top: 3px;
    left: 0; } }



.tariff-content__title {
  font-size: 21px;
  line-height: 26px;
  font-weight: 700;
  color: #000000;
  max-width: 390px;
  width: 100%;
  margin-bottom: 16px;
  a {
    color: #315EFB;
    &:hover {
      text-decoration: underline; } } }

.tariff-content__title-color-home {
  background-image: $orangeToPink;
  -webkit-background-clip: text;
  color: transparent; }

.tariff-content__title-color-business {
  background-image: $blueToPurple;
  -webkit-background-clip: text;
  color: transparent; }

.tariff-content__checkbox-color-home:before {
  background: url("/img/tarif_icon2.svg"); }

.tariff-content__checkbox-color-business:before {
  background: url("/img/check__arrBus.svg"); }

.tariff-content__text {
  color: #62687F;
  line-height: 21px;
  a {
    color: #315EFB;
    &:hover {
      text-decoration: underline; } } }

.tariff-result {
  background: #EEF2F4;
  padding: 50px 10px;
  color: #000; }

.tariff-result__inner {
  max-width: 700px;
  width: 100%;
  margin: 0 auto; }


.tariff-result__checkbox-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 44px; }


.tariff-result__select-checkbox {
  max-width: 218px;
  width: 100%;
  & .select2-container--krajee .select2-selection {
    border: 1px solid rgba(#8D96B2, .3);
    &:focus {
      border: 1px solid rgba(#8D96B2, .3); } } }

.tariff-result__dropdown {
  background: rgba(#C6CAD8, 0.3);
  color: #000000; }

.tariff-result__total {
  margin-bottom: 45px; }



.tariff-result__total-title {
  font-size: 26px;
  line-height: 32px;
  margin-bottom: 23px; }

.total-list-item {
  display: flex;
  justify-content: space-between;
  & + .total-list-item {
    margin-top: 25px; } }


.total-list-item-text {
  font-size: 21px;
  line-height: 26px; }

.item-text-main {
  font-weight: 700; }

.total-btn {
  display: block;
  margin: 0 auto; }
.tariff-business__tabs {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  color: #1D1D1F;
  border-bottom: 0.5px solid #939597;
  & .tabs-list {
    display: flex;
    justify-content: space-between; } }
.business__tab {
  padding: 0 29px; }

.business__tab.active {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    background-image: $blueToPurple;
    width: 100%;
    height: 4px; } }
//
.business__tab.active {
  background-image: $blueToPurple;
  -webkit-background-clip: text;
  color: transparent; }
//  border: none

.business__tab {
  line-height: 21px;
  padding-bottom: 21px; }
.tariff-services__title {
  margin-bottom: 22px;
  background-image: $blueToPurple;
  -webkit-background-clip: text;
  color: transparent; }

.tariff-services__title-plus {
  margin-bottom: 40px;
  background-image: $blueToPurple;
  -webkit-background-clip: text;
  color: transparent; }

.tariff-title-business {
  color: #000000;
  margin-bottom: 23px;
  span {
    background-image: $blueToPurple;
    -webkit-background-clip: text;
    color: transparent; } }

.tariff__title-home {
  color: #000000;
  margin-bottom: 23px;
  span {
    background-image: $orangeToPink;
    -webkit-background-clip: text;
    color: transparent; } }

.colocation__support .order {
  background-color: #1F2431; }

.consultation,
.order {
  padding: 50px 0; }

.order__title {
  max-width: 585px;
  width: 100%;
  margin-bottom: 18px; }

.order__text {
  max-width: 622px;
  width: 100%;
  margin-bottom: 30px;
  font-size: 32px;
  line-height: 39px; }

.consultation__numb {
  color: $secondary_color;
  margin-bottom: 30px;
  display: block;
  &:hover {
    color: $secondary_color; } }

.order {
  .consultation-content {
    max-width: 100%; }
  .consultation-form {
    margin: 0; }
  .consultation__text {
    font-size: 24px;
    line-height: 30px;
    font-weight: 500; }
  .consultation__subtext {
    max-width: 450px;
    width: 100%; } }

.video__consultation {
  background-color: #1F2431; }

.phone-support {
  padding: 71px 0;
  background-color: #1F2431; }

.consultation__inner {
  display: flex;
  justify-content: space-between; }

.consultation-content {
  max-width: 386px;
  width: 100%; }

.phone-support__content {
  max-width: 590px;
  width: 100%; }

.phone-support__form {
  margin: 0;
  align-self: center; }

.consultation__title {
  text-align: left;
  margin-bottom: 47px; }

.phone-support__title {
  margin-bottom: 18px;
  text-align: left; }

.title-color-home {
  text-align: center;
  background-image: $orangeToPink;
  -webkit-background-clip: text;
  color: transparent; }

.title-color-bus {
  text-align: left;
  background-image: $blueToPurple;
  -webkit-background-clip: text;
  color: transparent; }

.title-color-tv {
  text-align: left;
  background-image: $purpleToPink;
  -webkit-background-clip: text;
  color: transparent; }

.consultation__title-home {
  text-align: left;
  background-image: $orangeToPink;
  -webkit-background-clip: text;
  color: transparent;
  margin-bottom: 47px; }

.consultation__title-bus {
  text-align: left;
  background-image: $blueToPurple;
  -webkit-background-clip: text;
  color: transparent;
  margin-bottom: 47px; }
.consultation-form {
  margin: 0 87px 0 0; }

.consultation__text {
  color: #8D96B2;
  font-size: 27px;
  line-height: 33px;
  font-weight: 600;
  margin-bottom: 47px; }

.consultation__subtext {
  color: #8D96B2;
  font-size: 23px;
  line-height: 28px; }

.phone-support__text {
  max-width: 532px;
  width: 100%;
  color: #8D96B2;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 25px; }

.phone-support__numb {
  font-size: 24px;
  line-height: 30px;
  font-weight: 700; }



.calculate {
  color: #1d1d1f;
  padding: 39px 0 50px; }

.calculate__title {
  margin-bottom: 21px; }

.calculate__form {
  max-width: 683px;
  width: 100%;
  margin: 0 auto; }

.calculate__form-item {
  margin-bottom: 20px; }

.calculate__form-item-last {
  display: flex;
  & > .calculate__list-item {
    margin-left: 40px; } }

.calculate__form-title {
  font-size: 32px;
  line-height: 39px;
  margin-bottom: 5px; }

.calculate__list-item {
  & + .calculate__list-item {
    margin-top: 10px; } }

.calculate__form-position {
  display: flex;
  justify-content: space-between; }

.calculate__list-item-content {
  font-size: 21px;
  line-height: 40px;
  max-width: 350px;
  width: 100%; }

.calculate__list-item input[type="number"] {
  padding: 0 11px;
  color: #315EFB;
  border: 1px solid #8D96B2;
  border-radius: 4px;
  width: 100px;
  height: 40px;
  &:focus {
    box-shadow: 0 0 0 3px rgba(#2997FF, .5); } }

.check {
  line-height: 26px;
  display: inline-flex;
  align-items: center; }

.check__box {
  margin-right: 18px;
  vertical-align: middle;
  min-width: 20px;
  height: 20px; }

.check-primary__box {
  border-radius: 50%;
  background-color: #C6CAD8; }

.check-secondary__box {
  border: 2px solid #C6CAD8;
  border-radius: 6px;
  background-color: white; }

.check__input {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0); }

.check-primary__input {
  &:checked + .check__box {
    background-color: #fff;
    border: 5px solid #8D96B2; }
  &:focus + .check__box {
    box-shadow: 0 0 0 3px rgba(#2997FF, .5); }
  &:checked:focus + .check__box {
    box-shadow: 0 0 0 3px rgba(#2997FF, .5); } }

.check-secondary__input {
  &:checked + .check-secondary__box {
    display: block;
    background-image: url("../img/Check.svg");
    height: 20px;
    width: 20px;
    background-repeat: no-repeat;
    background-position: bottom;
    background-position-y: -2px;
    background-color: #8D96B2;
    border-color: #8D96B2; }
  &:focus + .check-secondary__box {
    box-shadow: 0 0 0 3px rgba(#2997FF, .5); }
  &:checked:focus + .check-secondary__box {
    box-shadow: 0 0 0 3px rgba(#2997FF, .5); } }

.calc-input {
  display: block;
  margin: 0 auto;
  max-width: 280px;
  width: 100%;
  background: rgba(#C6CAD8, 0.35);
  padding: 15px 33px;
  border: 1px solid rgba(#8D96B2, 0.35);
  & + .calc-input {
    margin-top: 15px; }
  &:focus {
    box-shadow: 0 0 0 3px rgba(#2997FF, .5); } }

.calc-btn-m {
  margin: 40px auto 0; }

.phone-info {
  color: #000000;
  padding: 50px 0 34px;
  background-color: #EEF2F4; }

.rate-phone {
  background-color: #EEF2F4;
  .rate-item__title {
    font-size: 35px;
    line-height: 39px; }
  .rate-item__item + .rate-item__item {
    margin-top: 18px; } }
.phone-info__list + .phone-info__list {
  margin-top: 22px; }

.phone-info__list-w {
  max-width: 586px;
  width: 100%; }
.phone-info__title {
  margin-bottom: 44px; }

.phone-info__definition-bus {
  &:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: #000; } }

.phone-bus-title {
  margin: 0 auto;
  margin-bottom: 44px;
  max-width: 815px;
  width: 100%;
  span {
    background-image: $blueToPurple;
    -webkit-background-clip: text;
    color: transparent; } }
.phone-info__content {
  max-width: 750px;
  width: 100%;
  margin: 0 auto; }
.phone-info__text {
  margin-bottom: 22px;
  & span {
    font-weight: 700; } }

.phone-info__text-width {
  max-width: 540px;
  width: 100%; }

.phone-info__text-width-bus {
  max-width: 712px;
  width: 100%; }
.phone-info__text-width-last {
  max-width: 640px;
  width: 100%; }

.phone-columns {
  padding: 40px 0 38px; }

.phone-columns__wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.phone__col {
  padding-left: 57px;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 50px;
    height: 50px; } }




.phone-col-1 {
  max-width: 233px;
  width: 100%;
  &:before {
    left: -58px; } }


.phone-col-home-1:before {
  background: url("../img/icons/phone-icon1.svg") no-repeat;
  top: -16px; }

.phone-col-bussines-1:before {
  background: url("../img/icons/phone-icon01.svg") no-repeat; }


.phone-col-2 {
  max-width: 374px;
  width: 100%; }


.phone-col-home-2:before {
  background: url("../img/icons/phone-icon2.svg") no-repeat;
  left: 15px; }

.phone-col-bussines-2:before {
  background: url("../img/icons/phone-icon02.svg") no-repeat;
  left: 15px; }


.phone-col-3 {
  max-width: 256px;
  width: 100%; }


.phone-col-home-3:before {
  background: url("../img/icons/phone-icon3.svg") no-repeat;
  left: 18px; }

.phone-col-bussines-3:before {
  background: url("../img/icons/phone-icon03.svg") no-repeat;
  left: 18px; }

.phone__col-title {
  color: #1d1d1f;
  line-height: 17px;
  margin-bottom: 16px; }
.phone__col-text {
  color: #62687F;
  font-size: 14px;
  line-height: 17px; }

.colocation-info {
  padding: 40px 0 45px; }

.colocation-info__title {
  color: #000;
  margin-bottom: 22px;
  text-align: left; }

.colocation-info__subtitle {
  color: #000;
  font-size: 32px;
  line-height: 39px;
  margin-bottom: 25px;
  max-width: 800px;
  width: 100%; }

.colocation-info__text-content {
  color: #62687F;
  font-size: 21px;
  line-height: 26px;
  max-width: 1045px;
  width: 100%; }

.colocation-info__text-block + .colocation-info__text-block {
  margin-top: 30px; }

.colocation-btn {
  display: inline-block;
  background: $blueToPurple; }

.colocation-button {
  background-color: #EEF2F4;
  padding: 50px 0; }

.btn__wrap {
  text-align: center; }

.colocation-info-provide {
  padding: 48px 0 34px;
  color: #000; }

.colocation-info-provide__title {
  text-align: left;
  margin-bottom: 24px; }

.colocation-info-provide__inner {
  display: flex; }

.colocation-info-provide__list-item {
  list-style: disc;
  font-size: 25px;
  line-height: 31px;
  margin-bottom: 28px;
  &:nth-child(odd) {
    width: 35%; }
  &:nth-child(even) {
    width: 45%; } }

.colocation-info-provide__list {
  padding: 0 50px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%; }



.colocation-info-services__title {
  background-image: $orangeToPink;
  -webkit-background-clip: text;
  color: transparent;
  margin-bottom: 41px; }

.colocation-info-services__title-bus {
  background-image: $blueToPurple;
  -webkit-background-clip: text;
  color: transparent;
  margin-bottom: 41px; }



.colocation-info-services {
  background-color: #1F2431;
  padding: 40px 0 0; }

.colocation-info-services__inner {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between; }

.colocation-info-services__item {
  margin-bottom: 40px; }
.colocation-info-services__text {
  font-size: 32px;
  line-height: 39px;
  font-weight: 700;

  margin-bottom: 13px; }

.colocation-info-services__text1 {
  max-width: 207px;
  width: 100%; }

.colocation-info-services__text2 {
  max-width: 320px;
  width: 100%; }


.colocation-info-services__text3 {
  max-width: 252px;
  width: 100%; }

.colocation-info-services__img {
  margin-bottom: 15px; }

.colocation-info-services__link {
  position: relative;
  color: #2997FF;
  font-size: 21px;
  line-height: 26px;
  &:after {
    padding-left: 5px;
    content: url("../img/arr_link.svg");
    height: 13px;
    width: 9px; }
  &:hover {
    color: #2997FF;
    text-decoration: underline; }
  &:focus {
    text-decoration: none;
    color: #2997FF; } }

.colocation-info-param {
  padding: 50px 0 0; }

.colocation-info-param__title {
  color: #000;
  text-align: left;
  margin-bottom: 23px; }

.colocation-info-param__inner {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-left: 42px; }

.colocation-info-param__item {
  margin-bottom: 30px;
  max-width: 33%;
  width: 100%; }

.param__item {
  display: flex;
  flex-direction: column;
  align-items: flex-end; }

.param__item-w {
  max-width: 236px;
  width: 100%; }

.colocation-info-param__item-title {
  color: #000;
  font-size: 26px;
  line-height: 32px;
  font-weight: 700;
  margin-bottom: 11px; }

.colocation-info-param__list {
  color: #62687F;
  font-size: 16px;
  line-height: 20px; }

.colocation-info-param__list-item:before {
  display: inline-block;
  content: "-";
  margin-left: -11px; }

.SLA {
  padding: 108px 0; }

.SLA__inner {
  max-width: 935px;
  width: 100%;
  margin: 0 auto;
  text-align: center; }

.SLA__title {
  color: #000;
  margin-bottom: 19px; }

.SLA__text {
  color: #62687F;
  font-size: 32px;
  line-height: 39px;
  margin-bottom: 27px; }

.tariff__content {
  padding: 39px 0 45px;
  color: #000; }

.phone-tariff__title {
  max-width: 838px;
  width: 100%;
  margin: 0 auto 32px;
  text-align: center;
  span {
    background-image: $orangeToPink;
    -webkit-background-clip: text;
    color: transparent; } }

.phone-tariff__title-bus {
  max-width: 1014px;
  width: 100%;
  margin: 0 auto 32px;
  text-align: center;
  span {
    background-image: $blueToPurple;
    -webkit-background-clip: text;
    color: transparent; } }

.tariff__name {
  font-size: 32px;
  line-height: 35px;
  text-align: center;
  margin-bottom: 40px;
  font-weight: 700; }

.tariff__list {
  max-width: 642px;
  width: 100%;
  margin: 0 auto;
  font-size: 21px;
  line-height: 26px; }

.tariff__list-item {
  position: relative;
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
  padding-left: 35px;
  & + .tariff__list-item {
    margin-top: 27px; }
  &:before {
    content: '';
    background: url("/img/tariff_icon.svg");
    width: 21px;
    height: 16px;
    position: absolute;
    top: 3px;
    left: 0; }
  &:last-child {
    span {
      color: #C6CAD8; } }
  a {
    color: #315EFB;
    &:hover {
      text-decoration: underline; } } }

.tv__tariff .tariff__list-item a {
  position: relative;
  background-image: $purpleToPink;
  -webkit-background-clip: text;
  color: transparent;
  font-weight: 700;
  text-decoration: underline; }

.tv__tariff .tariff__list-item a {
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-image: linear-gradient(90deg,#fc6a91,#892fc5);
    left: 0;
    bottom: 0;
    cursor: pointer; } }

.settings-tv {
  padding: 50px 0; }

.settings-tv--wrapper {
 }  //text-align: center

.settings-tv--wrapper img {
  max-width: 100%;
  display: block;
  margin:  auto;
  margin-bottom: 33px; }

.settings-tv__info {
  margin-left: 45px; }

.settings-tv__main-title {
  width: 84%;
  font-weight: bold;
  font-size: 48px;
  line-height: 58px;
  background-image: linear-gradient(90deg,#FC6f91,#892FC5);
  -webkit-background-clip: text;
  color: transparent;
  margin-bottom: 37px; }

.settings-tv__main-subtext {
	font-weight: 500;
	font-size: 24px;
	line-height: 29px;
	color: #6e6e73;
	width: 90%;
	margin-bottom: 44px; }

.link-item {
  display: table;
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  color: #315EFB;
  text-decoration: none;
  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
    color: #315EFB; } }

.link-item+.link-item {
  margin-top: 17px; }

.phone-tariff__card-row {
  padding-bottom: 40px; }
.phone-tariff__card-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -75px; }

.phone-tariff__card-item {
  flex: 0 1 33.333%;
  display: flex;
  flex-direction: column;
  padding: 0 75px;
  margin-bottom: 30px; }
.img-wrap {
  text-align: center;
  margin-bottom: 22px; }

.phone-tariff__card-title {
  //flex-grow: 1
  font-size: 21px;
  color: #000;
  margin-bottom: 9px; }

.phone-tariff__card-text {
  color: #62687F;
  font-size: 14px;
  line-height: 17px; }

.phone-tariff__subtitle {
  font-size: 32px;
  line-height: 32px;
  color: #000;
  font-weight: 700;
  text-align: center;
  margin-bottom: 26px; }

.link-wrapper {
  text-align: center; }

.phone-tariff__link {
  display: inline-block;
  cursor: pointer;
  position: relative;
  margin-top: 36px;
  color: #315EFB;
  font-size: 21px;
  line-height: 32px;
  padding-left: 40px;
  &:before {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    content: '';
    background: url("../img/link_phone.svg") no-repeat;
    height: 28px;
    width: 27px; }
  &:hover {
    color: #315EFB;
    text-decoration: underline; }
  &:focus {
    text-decoration: none;
    color: #315EFB; } }

.phone-tariff__calc {
  padding: 45px 0;
  color: #000;
  background: #EEF2F4; }

.phone-tariff__calc-inner {
  max-width: 700px;
  width: 100%;
  margin: 0 auto; }

.phone-main__title {
  color: #000;
  margin-bottom: 6px; }


.none {
  display: none; }

.opacity {
  opacity: 0; }


.tv__header {
  background-image: url("../img/tv_back/back.jpg");
  background-color: #1f2431;
  background-repeat: repeat-x;
  background-size: cover;
  animation: background-logo 100s linear infinite;
  animation-delay: 0s;
  animation-duration: 300s; }


.tv__header-inner {
  padding: 85px 0 88px;
  text-align: center; }

.tv__logo {
  margin-bottom: 20px; }

.tv__header-title {
  max-width: 792px;
  width: 100%;
  margin: 0 auto 18px;
  color: #fff; }

.tv__header-text {
  max-width: 700px;
  width: 100%;
  margin: 0 auto 27px;
  font-size: 32px;
  line-height: 39px; }


.btn__tv {
  display: inline-block;
  padding-left: 48px;
  padding-right: 48px;
  font-size: 21px;
  background: $purpleToPink; }

.tv__nav {
  padding: 50px 0 20px; }

.tv__nav-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }

.tv__nav-item {
  position: relative;
  height: 170px;
  flex-basis: 23%;
  border-radius: 18px;
  margin-bottom: 40px; }

.tv__nav-item:nth-child(1) {
  background: url("../img/tv_nav/card1.jpg") no-repeat; }

.tv__nav-item:nth-child(2) {
  background: url("../img/tv_nav/card2.jpg") no-repeat; }

.tv__nav-item:nth-child(3) {
  background: url("../img/tv_nav/card3.jpg") no-repeat; }

.tv__nav-item:nth-child(4) {
  background: url("../img/tv_nav/card4.jpg") no-repeat; }

.tv__nav-item-text {
  position: absolute;
  bottom: 17px;
  left: 25px;
  font-size: 21px;
  line-height: 32px; }

.tv_rate {
  background-color: #F5F5F7; }

.tv_rate .rate-item__title {
  font-size: 35px;
  line-height: 39px; }

.rate__title-tv {
  max-width: 762px;
  width: 100%;
  margin-bottom: 33px; }

.title-tv {
  text-align: left;
  color: #000;
  span {
    background-image: $purpleToPink;
    -webkit-background-clip: text;
    color: transparent;
    text-align: left; } }


.rate__text-tv {
  margin-bottom: 10px;
  text-align: left;
  max-width: 650px;
  width: 100%; }

.tv_rate .rate-item__title {
  background-image: $purpleToPink;
  -webkit-background-clip: text;
  color: transparent; }

.tv_rate .rate-item__item {
  color: #8D96B2;
  margin-bottom: 13px;
  &:last-child {
    margin-bottom: 0; } }


.rate-item__item-icon:after {
  content: 'HD';
  border: 1px solid #8D96B2;
  border-radius: 3px;
  padding: 0 2px;
  margin-left: 5px; }

.rate-item__item.tv-color {
  background-image: $purpleToPink;
  -webkit-background-clip: text;
  color: transparent;
  font-weight: 700; }

.tv__speak {
  padding: 39px 0 0; }

.tv-price {
  color: #ffffff; }

.tv__speak-text {
  max-width: 961px;
  width: 100%; }

.tv__text {
  color: #6E6E73;
  margin-bottom: 44px;
  font-size: 24px;
  line-height: 29px; }

.tv__speak-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }

.tv__app-img {
  max-width: 100%; }

.tv__speak-list-item {
  margin-bottom: 45px;
  margin-right: 15px;
  flex: 0 1 280px;
  height: 170px;
  border-radius: 18px; }

.tv__speak-list-item:nth-child(1) {
  background: url("../img/tv/card1.jpg") no-repeat; }

.tv__speak-list-item:nth-child(2) {
  background: url("../img/tv/card2.jpg") no-repeat; }

.tv__speak-list-item:nth-child(3) {
  background: url("../img/tv/card3.jpg") no-repeat; }

.tv__speak-title {
  max-width: 961px;
  width: 100%;
  margin-bottom: 32px; }

.tv__app {
  background-color: #F5F5F7;
  padding: 50px 0;
  .link__wrapper {
    flex-wrap: wrap; } }

.tv__app-img {}

.tv__app-title {
  max-width: 957px;
  width: 100%;
  margin-bottom: 17px; }
.tv__app-text {
  max-width: 739px;
  width: 100%;
  margin-bottom: 29px; }

.tv__app .btn__link {
  margin-bottom: 10px; }

.tv_form-help .phone-support {
  background-color: #ffffff; }

.vols__about {
  background-color: #eef2f4;
  padding: 39px 0 52px; }

.vols__about-item + .vols__about-item {
  margin-top: 31px; }

.vols__about-item-title {
  color: #000000;
  text-align: left;
  margin-bottom: 31px; }

.vols__about-item-text {
  color: #1D1D1F;
  font-size: 32px;
  line-height: 39px;
  span {
    font-weight: 700; } }

.thesis {
  padding: 43px; }

.thesis-list {
  max-width: 1001px;
  width: 100%; }

.thesis-list-item {
  position: relative;
  color: #1D1D1F;
  font-size: 32px;
  line-height: 39px;
  text-indent: 43px;
  &:before {
    content: '';
    background: url("/img/tariff_icon.svg");
    width: 21px;
    height: 16px;
    position: absolute;
    top: 11px;
    left: 0; } }

.vols__list-item {
  &:nth-child(1) {
    max-width: 718px;
    width: 100%; }
  &:nth-child(2) {
    max-width: 842px;
    width: 100%; }
  &:nth-child(3) {
    max-width: 706px;
    width: 100%; }
  &:nth-child(4) {
    max-width: 754px;
    width: 100%; }
  & + & {
    margin-top: 48px; } }



.vols__block-info {
  background-color: #eef2f4;
  padding: 39px 0 46px; }

.vlan__block-info {
  background-color: #eef2f4;
  padding: 39px 0 46px; }

.block-info__title-tv {
  margin-bottom: 40px;
  color: #000;
  text-align: left; }

.vols__block-info .column__img {
  margin: 0 0 19px 0; }

.vols__block-info .column__title {
  color: #000000; }

.modal-dialog {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  padding: 38px 0 40px;
  color: #8D96B2;
  text-align: center;
  min-height: 450px;
  max-width: 450px;
  width: 100%; }

.modal-content {
  background-color: #343B4C;
  border-radius: 18px;
  border: none; }

.modal-header {
  padding: 0;
  border: none; }

.modal-header .close {
  position: absolute;
  z-index: 1000;
  top: 15px;
  right: 15px;
  color: $secondary_color;
  font-size: 28px;
  opacity: 1;
  text-shadow: none; }

.modal__send-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 450px;
  max-width: 325px;
  width: 100%;
  margin: 0 auto; }

.modal__send-title {
  background-image: $purpleToPink;
  -webkit-background-clip: text;
  color: transparent;
  font-weight: 700;
  font-size: 26px;
  line-height: 26px;
  max-width: 315px;
  width: 100%;
  margin: 0 auto 19px; }

.modal__send-text {
  color: #8D96B2;
  line-height: 17px;
  text-align: center; }

.modal__send-sub-title {
  line-height: 30px; }

.modal__send-sub {
  margin-bottom: 32px; }

.modal__send-form {
  display: flex;
  flex-direction: column; }


.modal__send-form-btn {
  display: inline-block;
  &:hover {
    color: #ffffff; } }

input.modal__send-form-item {
  padding: 17px 10px 16px;
  &::placeholder {
    color: #8D96B2; } }


textarea.modal__send-form-item {
  resize: none;
  height: 100px;
  margin-bottom: 40px;
  padding: 10px; }

.modal__send-form-item {
  max-width: 280px;
  width: 100%;
  margin: 0 auto;
  outline: none;
  border-radius: 16px;
  border: #8D96B2 1px solid;
  background: transparent;
  & + & {
    margin-top: 17px; } }

.modal__send-img {
  width: 65px;
  height: 48px;
  margin-bottom: 22px; }

.modal__send-item {
  display: flex;
  justify-content: space-between;
  padding: 0 32px;
  margin-bottom: 30px; }

.modal__send-item-res {
  color: #ffffff;
  line-height: 30px; }

.modal {
  text-align: center;
  padding: 0!important;
  z-index: 5001;
 }  //bottom: auto
.modal-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important; }

.modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px /* Adjusts for spacing */; }


.vlan__about {
  color: #1D1D1F;
  padding: 40px 0; }

.vlan__about-title {
  color: #000;
  text-align: left;
  margin-bottom: 32px; }

.vlan__about-inner {
  font-size: 32px;
  line-height: 39px; }

.vlan__about-list-item {
  position: relative;
  text-indent: 43px;
  &:before {
    content: '';
    background: url("/img/tariff_icon.svg");
    width: 21px;
    height: 16px;
    position: absolute;
    top: 11px;
    left: 0; } }

.vlan__about-text {
  margin-bottom: 50px; }

.vlan__about-list-title {
  font-size: 32px;
  line-height: 39px;
  margin-bottom: 32px; }

.vlan__about-list-item {
  margin-bottom: 40px;
  &:last-child {
    margin-bottom: 0; } }

.vlan__block-info {
  .column__title {
    color: #000; } }

.col-width {
  max-width: 347px;
  width: 100%; }

.vlan__list {
  max-width: 909px;
  width: 100%; }

.vlan__list-item {
  margin-bottom: 37px;
  &:last-child {
    margin-bottom: 0; } }

.vlan__support {
  background-color: #EEF2F4; }

.sysadmin-offer {
  background-color: #eef2f4;
  padding: 39px 0 0px; }

.sysadmin-offer__content-title {
  color: #000;
  text-align: left;
  margin-bottom: 32px; }

.sysadmin-offer__content-text {
  font-size: 32px;
  line-height: 39px;
  color: #62687F;
  margin-bottom: 37px; }

.sysadmin-offer__content-subtext {
  color: #1D1D1F;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 44px; }

.sysadmin__block-info {
  justify-content: space-between;
  .column__img {
    margin: 0 0 18px 0; }
  .column__title {
    color: #000; }
  .block-info__column:nth-child(odd) {
    flex-basis: 55%; }
  .block-info__column:nth-child(even) {
    flex-basis: 36%; } }

.sysadmin-support {
  background-color: #1F2431; }

.sysadmin-thesis {
  padding: 50px 0 0;
  background-color: #EEF2F4; }

.sysadmin-thesis__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.sysadmin__list-item {
  flex-basis: 45%;
  margin-bottom: 50px; }
.sysadmin__list-title {
  position: relative;
  color: #000;
  font-size: 26px;
  line-height: 32px;
  font-weight: 700;
  text-indent: 36px;
  &:before {
    content: '';
    background: url("/img/tariff_icon.svg") no-repeat;
    width: 21px;
    height: 16px;
    position: absolute;
    top: 9px;
    left: 0; } }

.sysadmin-thesis__title {
  color: #000;
  text-align: left;
  margin-bottom: 33px; }

.sysadmin__list-title {
  margin-bottom: 15px; }

.sysadmin__list-text {
  color: #62687F; }

.sysadmin-sertification {
  background-color: #EEF2F4;
  padding: 50px 0 40px;
  text-align: center; }

.sysadmin-sertification__img {
  max-width: 100%;
  margin-bottom: 42px; }


.sysadmin-sertification__content {
  margin-left: 150px;
  text-align: left;
  color: #000; }




.sysadmin-sertification__title {
  font-weight: 700;
  margin-bottom: 29px;
  font-size: 32px;
  line-height: 39px;
  max-width: 488px;
  width: 100%; }

.sysadmin-sertification__text {
  max-width: 680px;
  width: 100%;
  font-size: 21px;
  line-height: 26px; }

.sysadmin-about {
  background-color: #EEF2F4;
  padding: 39px 0 80px; }

.sysadmin-about__inner {
  max-width: 570px;
  width: 100%;
  margin: 0 auto; }

.sysadmin-about__title {
  color: #1d1d1f;
  margin-bottom: 9px; }

.sysadmin-about__text {
  color: #62687F;
  text-align: center;
  font-size: 24px;
  line-height: 29px; }

.sysadmin-calc {
  background-color: #EEF2F4; }

.sysadmin-view {
  background-color: #fff;
  padding: 43px 0 45px; }

.sysadmin-calc__tabs {
  .nav-tabs {
    border: none;
    display: flex;
    justify-content: center;
    color: #000; }
  .nav-tabs > li > a {
    border: none; }
  .nav-tabs > li.active > a, .nav-tabs > li.active > a:hover,
  .nav-tabs > li.active > a:focus {
    border: none;
    background: none;
    background-image: $blueToPurple;
    -webkit-background-clip: text;
    color: transparent;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      background-image: $blueToPurple;
      width: 100%;
      height: 4px; } }

  .nav-tabs > li > a:hover {
    background: none;
    border: none; } }

.sysadmin-view__inner {
  max-width: 542px;
  width: 100%;
  margin: 0 auto; }

.calc__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  & + & {
    margin-top: 25px; } }

.sysadmin-view__title {
  font-size: 32px;
  line-height: 39px;
  color: #1D1D1F;
  margin-bottom: 16px; }

.calc__item-text {
  color: #1D1D1F;
  font-size: 21px;
  line-height: 26px;
  max-width: 350px; }

.pages {
  color: #000;
  padding: 50px 0; }

.pages__title {
  text-align: left;
  margin-bottom: 30px;
  color: #000; }

.calc__item-input {
  padding: 0 11px;
  color: #315EFB;
  border: 1px solid #8D96B2;
  border-radius: 4px;
  width: 100px;
  height: 40px;
  &:focus {
    box-shadow: 0 0 0 3px rgba(#2997FF, .5); } }

.sysadmin-view__bottom-text {
  font-size: 21px;
  line-height: 26px;
  margin-top: 20px;
  color: #000; }


.lds-spinner {
  color: #fff;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  display: none;
  margin: 10px auto; }
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite; }

.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #fff; }

.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s; }

.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s; }

.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s; }

.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s; }

.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s; }

.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s; }

.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s; }

.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s; }

.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s; }

.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s; }

.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s; }

.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s; }

@keyframes lds-spinner {

  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }


.voted-installers {
  color: #000;
  padding: 39px 0 50px; }

.voted-installers__title {
  background-image: $purpleToPink;
  -webkit-background-clip: text;
  color: transparent;
  text-align: left;
  margin-bottom: 13px; }

.lead {
  margin: 40px 0 21px; }

.lead__text {
  text-align: center;
  max-width: 833px;
  width: 100%;
  font-size: 21px;
  line-height: 26px;
  margin: 0 auto 35px; }

.cancel-on-png, .cancel-off-png, .star-on-png, .star-off-png, .star-half-png {
  font-size: 24px;
  color: #315EFB; }

.form-control {
  margin: 20px 0 44px;
  color: #000;
  resize: none;
  border: 1px solid #1D1D1F;
  &::placeholder {
    color: #C6CAD8; } }

.form-control__summ {
  border-radius: 16px;
  padding: 22px 20px;
  border: 1px solid #8D96B2;
  margin-bottom: 25px; }
.payment__cash {
  position: relative;
  color: #315EFB;
  font-size: 21px;
  line-height: 26px;
  &:after {
    padding-left: 5px;
    content: url("../img/arr__bot.svg");
    height: 13px;
    width: 9px; }
  &:hover {
    color: #315EFB; }
  &:focus {
    text-decoration: {}
    color: #315EFB; } }

.voted__btn {
  padding: 10px 22px;
  margin-top: 25px; }

.voted-operators__btn {
  padding: 10px 22px;
  margin: 0 auto; }
.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #EEF2F4; }
.table-bordered {
  border: 1px solid #EEF2F4; }

.table-bordered > thead > tr > th, .table-bordered > tbody > tr > th, .table-bordered > tfoot > tr > th, .table-bordered > thead > tr > td, .table-bordered > tbody > tr > td, .table-bordered > tfoot > tr > td {
  border: 1px solid #EEF2F4; }

.voted__comment {
  margin: 10px 0 20px; }

.voted__thanks {
  font-size: 21px;
  font-weight: 700;
  color: #BF5AF2; }

.voted-operators {
  padding: 46px 0 50px;
  color: #000; }

.voted-operators__title {
  background-image: $purpleToPink;
  -webkit-background-clip: text;
  color: transparent;
  margin-bottom: 37px; }

.block_stars {
  font-size: 8px;
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    color: #315EFB; } }

.voted-operators__textarea {
  margin: 0 auto 25px;
  padding: 10px;
  max-width: 525px;
  width: 100%;
  min-height: 107px; }

// sertificate
//.sertificate-slider
//  margin: 0 auto

.sertificate {
  padding: 50px 0; }

.sertificate-slider {
  margin-bottom: 20px; }

.sertificate-slider-item {
  img {
    max-width: 400px;
    margin: 0 auto; } }
.sertificate-slider-nav {
  max-width: 700px;
  width: 100%;
  margin: 0 auto; }
.sertificate-slider-nav__item {
  //width: 350px
  img {
    width: 80%;
    margin: 0 auto; } }


.sertificate-slider-nav {}


// sertificate

.site-error {
  color: #000;
  padding: 56px 0 191px; }

.site-error__inner {
  text-align: center; }

.site-error__title {
  font-size: 300px;
  line-height: 262px;
  font-weight: 700;
  background-image: $purpleToPink;
  -webkit-background-clip: text;
  color: transparent; }

.site-error__sub-title {
  font-size: 29px;
  line-height: 35px;
  margin-bottom: 30px; }
.site-error__text {
  max-width: 293px;
  width: 100%;
  margin: 0 auto 35px;
  font-size: 21px;
  line-height: 26px;
  span {
    font-weight: 700; } }

.site-error__btn {
  display: inline-block;
  padding-top: 10px;
  padding-bottom: 10px;
  &:hover {
    color: #ffffff; } }


.citys {
  background: #BFC4E2;
  overflow: hidden; }

.cytys__preview {
  position: relative;
  padding: 135px 0; }

.cytys__preview-image {
  position: absolute;
  z-index: 9;
  top: 0;
  right: -295px;
  max-width: 100%;
  height: 100%;
  object-fit: cover; }

.cirys__preview-info {
  position: relative;
  max-width: 633px;
  width: 100%;
  z-index: 10; }

.citys__preview-title {
  font-size: 48px;
  font-weight: bold;
  line-height: 58px;
  color: #000000;
  margin-bottom: 30px; }

.citys__preview-text {
  width: 85%;
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
  color: #000000; }

.hashtek__content {
  padding: 43px 0; }

.hashtek__text {
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
  text-align: center;
  color: #000000;
  & span {
    background-image: linear-gradient(90deg,#fc6f91,#892fc5);
    -webkit-background-clip: text;
    color: transparent; } }

.citys-prices {
  background: #EEF2F4; }

.citys-prices__items {
  padding: 53px 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }

.items-prices__item {
  max-width: 525px;
  width: 100%;
  margin-bottom: 54px;
  &:last-child {
    margin-bottom: 0; }
  .item-prices__ico {
    margin-bottom: 20px; } }

.items-prices__info {}

.item-prices__info-title {
  font-weight: bold;
  font-size: 26px;
  line-height: 31px;
  color: #000;
  margin-bottom: 30px;
  width: 90%; }

.items-prices__item {
  &:nth-of-type(3) {
    .item-prices__info-title {
      width: 100%;
      background-image: linear-gradient(90deg,#fc6f91,#892fc5);
      -webkit-background-clip: text;
      color: transparent; } } }

.item-prices__info-text {
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  color: #62687F; }

.btn-city {
  background: linear-gradient(91.39deg, rgba(169, 201, 255, 0.65) 1.18%, rgba(255, 187, 236, 0.65) 98.82%), linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%); }

.btn-city__wrapper {
  padding: 50px 0;
  text-align: center; }

#sendModal.active {
  overflow-x: hidden;
  overflow-y: auto;
  opacity: 1;
  visibility: visible;
  transition: all 0.5s ease-in-out; }

.requisites {
  margin-top: 15px; }

.requisites-text {
  display: none;
  color: #000;
  padding: 0 15px;
  max-width: 350px;
  margin: 0 auto;
  margin-bottom: 30px; }

.month__check {
  padding: 0 23px;
  margin-top: 20px;
  margin-bottom: 30px;
  text-align: left!important; }

.month__check-item {
  position: relative;
  padding-left: 25px;
  margin: 15px 0;
  .agree__label {
    left: 0; } }

.month__check-label {
  color: #000;
  span {
    white-space: nowrap;
    color: #FF3562; } }

.pay__note {
  color: #8d96b2; }

.btnCall {
  background-color: #FC3EC1;
  position: fixed;
  right: 25px;
  bottom: 176px;
  width: 60px;
  height: 60px;
  border-radius: 60px;
  background-image: url(/img/telephone.svg);
  box-shadow: 0 3px 5px -1px #0003, 0 6px 10px 0 #00000024, 0 1px 18px 0 #0000001f;
  span {
    position: absolute;
    right: 50px;
    top: 18px;
    width: 190px;
    text-align: left;
    color: #000; } }

#openChat {
  span {
    position: absolute;
    right: 50px;
    top: 18px;
    width: 118px;
    text-align: left;
    color: #000; } }

#callModal {
  h3 {
    color: #000;
    font-size: 21px;
    font-weight: normal;
    margin-bottom: 25px; }
  .modal-dialog {
    width: 1000px;
    max-width: 90%; }
  .modal-content {
    background-image: linear-gradient(359.61deg, #A9C9FF .34%, #FFBBEC 99.68%); }
  .modal-header {
    .close {
      color: #fff; } } }

.callInput {
  width: 280px;
  max-width: 100%;
  height: 50px;
  border-radius: 16px;
  padding: 15px; }

.callSubmit {
  background-color: #000;
  color: #fff;
  height: 40px;
  font-size: 14px;
  padding: 0 40px;
  border-radius: 40px;
  font-weight: bold;
  margin-left: 45px; }

.call-body {
  max-width: 800px;
  padding: 50px 20px;
  margin: 0 auto;
  text-align: left; }

#callTimer {
  font-family: "roboto", sans-serif;
  color: #000;
  font-size: 21px;
  font-weight: 200;
  display: inline-block;
  margin-left: 50px;
  white-space: nowrap; }

.timerItem {
  display: inline-block;
  font-family: "roboto", sans-serif;
  font-weight: 200; }

.seconds {
  font-weight: normal; }

#callForm {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.h3-call2 {
  display: none; }

#commercialForm {
  .select2 {
    width: 254px!important;
    margin: 0 auto;
    margin-top: 15px;

    .select2-selection {
      background-color: rgba(31, 36, 49, .3); }

    .select2-selection__rendered {
      color: #8d96b2; }

    .select2-selection__arrow b {
      width: 13px;
      height: 8px;
      background: none;
      background-color: #8d96b2;
      mask-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEzIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik03LjA0MjIxIDYuODg4MjdMMTIuMzgyMyAxLjU0ODExQzEyLjUwNTkgMS40MjQ2IDEyLjU3NCAxLjI1OTczIDEyLjU3NCAxLjA4MzkyQzEyLjU3NCAwLjkwODExOSAxMi41MDU5IDAuNzQzMjQzIDEyLjM4MjMgMC42MTk3MzJMMTEuOTg5MSAwLjIyNjQ2N0MxMS43MzI5IC0wLjAyOTQzMzggMTEuMzE2NSAtMC4wMjk0MzM4IDExLjA2MDcgMC4yMjY0NjdMNi41NzY0NiA0LjcxMDcyTDIuMDg3MjMgMC4yMjE0OTFDMS45NjM2MiAwLjA5Nzk3OTcgMS43OTg4NCAwLjAyOTc4NTIgMS42MjMxNCAwLjAyOTc4NTJDMS40NDcyNCAwLjAyOTc4NTIgMS4yODI0NiAwLjA5Nzk3OTcgMS4xNTg3NSAwLjIyMTQ5MUwwLjc2NTY4IDAuNjE0NzU2QzAuNjQyMDcyIDAuNzM4MzY1IDAuNTczOTc1IDAuOTAzMTQ0IDAuNTczOTc1IDEuMDc4OTVDMC41NzM5NzUgMS4yNTQ3NSAwLjY0MjA3MiAxLjQxOTYzIDAuNzY1NjggMS41NDMxNEw2LjExMDYxIDYuODg4MjdDNi4yMzQ2MSA3LjAxMjA3IDYuNDAwMTcgNy4wODAwNyA2LjU3NjE3IDcuMDc5NjhDNi43NTI4NSA3LjA4MDA3IDYuOTE4MzEgNy4wMTIwNyA3LjA0MjIxIDYuODg4MjdaIiBmaWxsPSIjMUQxRDFGIi8+Cjwvc3ZnPgo="); }

    .select2-selection__placeholder {
      color: #8d96b2; } } }

.feedback {
  margin-top: 20px; }

#feedback-form {
  text-align: center;
  margin: 15px 0 25px; }

.feedback-questions {
  margin-top: 20px; }

.feedback-question {
  background-color: #8D96B2;
  border-radius: 17px;
  display: inline-block;
  padding: 7px 11px;
  font-size: 21px;
  margin: 5px 7px;
  cursor: pointer; }

.feedback-textarea {
  display: block;
  width: 100%;
  max-width: 525px;
  height: 106px;
  border: 1px solid #1D1D1F;
  border-radius: 4px;
  margin: 70px auto 25px;
  color: #1D1D1F;
  padding: 12px;
  font-size: 17px;
  outline: none; }

.btn-feedback {
  display: inline-block; }

.confirm {
  h1 {
    color: #1D1D1F;
    font-size: 48px;
    font-weight: 700;
    text-align: center;
    margin-top: 88px; }

  h2 {
    color: #1D1D1F;
    font-size: 32px;
    font-weight: 500;
    text-align: center;
    margin-top: 20px; } }

#confirmForm {
  margin: 0 auto;
  margin-top: 40px;
  padding-bottom: 80px;
  max-width: 700px;

  .calc-input {
    background-color: #E8E8E8;
    max-width: 320px; }

  .calculate__form-title {
    font-weight: 700;
    color: #1D1D1F;
    border: none;
    margin-bottom: 15px; }

  .check, label {
    color: #1D1D1F;
    font-size: 21px;
    font-weight: 500;
    &:active {
      outline: none; } }

  .calculate__list-item-content {
    input {
      border: 1px solid #8d96b2;
      border-radius: 4px;
      width: 100px;
      padding: 0 10px; } }

  .options-connect__select-checkbox {
    margin: 0 auto; }

  .calculate__form-item {
    margin-bottom: 25px; }

  .select2 {
    width: 350px !important; }

  .labelNumberIP .select2 {
    width: 100px !important; }

  .select2-container--krajee {
    .select2-selection {
      background: #E8E8E8;
      border: 1px solid #8d96b2; } } }

.labelWithoutTV {
  margin-top: 20px; }

.labelNumberIP {
  margin-bottom: 10px;

  .select2-container--krajee .select2-selection--single {
    border-radius: 4px; }

  .select2-container--krajee .select2-selection {
    background: none !important; } }

.confirm-warning {
  color: #FF3A44;
  font-size: 21px;
  line-height: 26px; }

.requirements {
  margin-top: 35px;

  .check {
    align-items: start; } }

.confirm-order {
  padding: 100px 0;
  color: #1d1d1f;
  text-align: center;

  h1 {
    font-size: 48px;
    color: #1d1d1f;
    font-weight: bold;
    text-align: center; }

  h2 {
    margin-top: 37px;
    font-size: 48px;
    font-weight: 700; }

  .check {
    align-items: flex-start; }

  .check__box {
    margin-top: 4px; }

  .calculate__list-item {
    text-align: left;
    margin-top: 50px; } }

#btnYesConfirmOrder {
  background-color: #1ED760;
  padding: 14px 66px;
  border-radius: 25px;
  color: #fff;
  margin-top: 45px; }

.confirm-questions {
  margin-top: 20px; }

.confirm-question {
  cursor: pointer;
  font-size: 21px;
  color: #fff;
  background-color: #8D96B2;
  display: inline-block;
  border-radius: 18px;
  padding: 7px 15px;
  margin: 10px;
  margin-bottom: 0; }

.confirm-textarea {
  max-width: 525px;
  width: 100%;
  height: 106px;
  border-radius: 4px;
  border: 1px solid #1D1D1F;
  margin-top: 80px;
  outline: none;
  padding: 15px; }

#btnNoConfirmOrder {
  font-size: 14px;
  color: #fff;
  background-color: #FF3A44;
  padding: 10px 21px;
  border-radius: 20px;
  margin-top: 25px; }

.confirm-questions_bottom {
  text-align: left;
  font-size: 21px;
  line-height: 25px;
  font-weight: 700;
  margin-top: 33px;

  a {
    color: #315EFB;
    text-decoration: underline; } }

#btnCloseNotReadyModal {
  color: #fff;
  border: 2px solid #8D96B2;
  border-radius: 20px;
  padding: 8px 0;
  min-width: 120px;
  background: none;
  margin-top: 25px;
  font-size: 14px; }

#btnAcceptNotReadyModal {
  padding: 8px 0;
  min-width: 120px;
  border: none;
  border-radius: 20px;
  background-color: #FF3A44;
  margin-top: 25px;
  font-size: 14px;
  color: #fff; }

.linkView {
  display: inline-block;
  margin-top: 17px; }

.rate-item-500 {
  background: rgb(176, 200, 250);
  &.rate-item__business-color {
    background: rgb(170, 150, 250); } }

.rate-item__item.rate-item__link a {
  display: inline-block;
  margin-top: 17px; }

.product-view-top {
  background: #E8E8E8;
  padding: 30px 0;

  h1 {
    color: #000;
    font-size: 48px;
    font-weight: bold; } }

.product-price {
  color: #000;
  font-size: 48px;
  font-weight: bold;
  padding: 35px 0;
  display: inline-block;
  vertical-align: middle; }

.product-view-img {
  background: #D9D9D9;
  text-align: center;
  padding: 20px 0;

  img {
    max-width: 400px; } }

.product-view-wrapper {
  max-width: 900px;
  margin: 0 auto;

  h1 {
    margin-top: 30px; } }

.product-btn {
  color: #fff;
  background: #1ED760;
  border-radius: 30px;
  padding: 10px 28px;
  vertical-align: middle;
  margin-left: 20px;
  font-size: 16px;

  &[disabled='disabled'] {
    background: #D9D9D9; }

  &:before {
    content: "";
    vertical-align: middle;
    margin-right: 8px;
    display: inline-block;
    width: 20px;
    height: 22px;
    background-image: url("/img/buy.svg");
    background-repeat: no-repeat; } }

.product-view-note {
  color: #000;
  background: #D9D9D9;
  padding: 20px 15px;
  border-radius: 12px;
  margin-bottom: 20px; }

.product-view-description {
  margin-top: 40px;

  h4 {
    color: #000;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 15px; }

  p {
    color: #000;
    font-size: 17px; } }

.product-view-characteristics {
  color: #000;
  padding: 40px 0;

  h4 {
    color: #000;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 15px; } }

.product-slider {
  .slick-list {
    height: 100%; }

  .slick-track {
    height: 100%; } }

.product-slider-item-wrapper {
  height: 570px;
  width: 400px;
  background: #fff;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 90%;
    max-height: 90%; } }

.product-slider-nav {
  max-width: 750px;
  margin: 0 auto;
  .slick-track {
    margin-top: 15px;
    justify-content: center; } }

.product-slider-item {
  height: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;

  img {
    width: 90%; } }

.product-slider-nav__item {

  img {
    border-radius: 20px;
    margin: 0 auto;
    width: 90%; } }

.product-view-block5 {
  padding: 40px 0;
  background: #E8E8E8; }

.block5-h4 {
  font-size: 48px;
  font-weight: bold;
  color: #000; }

.block5-text {
    font-size: 24px;
    color: #000;
    margin-top: 30px;
    line-height: 30px; }

.block5-list {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;

  li {
    max-width: 22%;

    img {
      max-width: 100%; } } }

.product-view-block2 {
  height: 600px;
  background-image: url("/img/block2.jpg");
  background-position-x: center;
  background-repeat: no-repeat;
  background-color: #E8E8E8;
  display: flex;
  align-items: center; }

.block2-h4 {
  color: #000;
  font-size: 48px; }

.block2-text {
  color: #000;
  font-size: 24px;
  line-height: 30px;
  margin-top: 30px; }

.product-view-block1 {
  background-color: #000;
  padding: 40px 0; }

.block1-h4 {
  font-size: 48px;
  text-align: left; }

.block1-text {
  margin-top: 25px;
  font-size: 24px;
  line-height: 30px;
  color: #86868B; }

.block1-list {
  margin: 80px 0;

  li {
    font-size: 21px;
    margin: 30px 0;
    vertical-align: middle;
    margin-left: 90px;

    &:after {
      content: "";
      display: inline-block;
      height: 25px;
      border-radius: 10px;
      vertical-align: bottom; }

    &:first-child {
      &:after {
        width: 250px;
        background-image: linear-gradient(90deg, rgba(#EEDCCE, 0) 0, #86868B 100%);
        margin-left: 47px; } }

    &:last-child {
      &:after {
        width: 540px;
        background-image: linear-gradient(90deg, #EEDCCE 0, #E29DBE 33%, #9B86DE 66%, #9ACFFA 100%);
        margin-left: 20px; } } } }

.product-view-block3 {
  background-color: #292929;
  padding: 40px 0; }

.block3-h4 {
  font-size: 48px;
  font-weight: bold; }

.block3-text {
  color: #86868B;
  margin-top: 25px; }

.block3-img {
  max-width: 90%;
  width: 800px;
  height: 280px;
  background-image: url("/img/block3.jpg");
  background-size: 100% auto;
  background-repeat: no-repeat;
  margin: 0 auto;
  margin-top: 40px; }

.product-view-block4 {
  background-color: rgb(231, 231, 233);
  padding: 100px 0;
  background-image: url("/img/block4.jpg");
  background-position-x: right;
  background-repeat: no-repeat; }

.block4-h4 {
  color: #000;
  font-size: 48px;
  font-weight: bold; }

.block4-text {
  font-size: 24px;
  color: #000;
  line-height: 30px;
  margin-top: 30px; }

.product-view-form {
  background-color: #1F2431;
  padding: 50px 0; }

.product-form-h4 {
  font-size: 40px;
  font-weight: bold; }

.product-form-text {
  font-size: 24px;
  color: #8D96B2;
  line-height: 30px;
  margin-top: 20px; }

.product-form-phone {
  font-size: 40px;
  display: inline-block;
  margin: 30px 0; }

.product-form-bottom-text {
  font-size: 24px;
  color: #8D96B2;
  line-height: 30px;

  a {
    color: #fff;
    text-decoration: underline; } }

.product-form, #homeForm {
  padding: 70px 100px;
  padding-bottom: 60px;
  background-image: url("/img/form-bg.jpg");
  background-size: cover;
  border-radius: 25px;
  text-align: center;
  margin-top: 60px; }

#homeForm {
  margin-top: 0; }

.product-form-input, .homeInput {
  background-color: rgb(147, 128, 167);
  width: 100%;
  font-size: 17px;
  padding: 14px;
  border-radius: 16px;
  color: #fff;
  margin-bottom: 25px;

  &::placeholder {
    color: #fff; } }

.product-form-btn {
  background-color: #000;
  font-size: 14px;
  font-weight: bold;
  padding: 10px 26px;
  border-radius: 30px; }

.product-view-slider {
  background-color: #E8E8E8;
  padding: 40px 0; }

.product-view-slider-h4 {
  color: #000;
  font-size: 48px;
  font-weight: bold; }

.product-view-slider-text {
  color: #000;
  font-size: 32px;
  line-height: 30px;
  margin-top: 25px; }

.product-list-slider__item {
  display: block;
  width: 330px;
  min-height: 407px;
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
  position: relative;
  text-align: center;
  margin: 15px; }

.product-list-slider__img {
  height: 220px;
  max-width: 100%; }

.product-list-slider__title {
  color: #1D1D1F;
  font-size: 18px;
  font-weight: bold;
  text-align: left; }

.product-list-slider__text {
  color: #1D1D1F;
  font-size: 16px;
  margin-top: 15px;
  text-decoration: underline;
  text-align: left; }

.product-list-social {
  position: absolute;
  right: 20px;
  bottom: 20px;

  li {
    display: inline-block; } }

.product-list-slider {
  padding: 0 50px;

  .slick-slide img {
    display: inline-block; }

  .slick-arrow {
    width: 48px;
    height: 48px;
    border-radius: 48px;
    background-color: #D0D0D0;
    font-size: 20px;
    position: absolute;
    top: 180px; }


  .slick-prev {
    left: 0; }

  .slick-next {
    right: 0; }

  .slick-dots {
    text-align: center;
    margin-top: 25px;

    li {
      font-size: 0;
      width: 15px;
      height: 15px;
      border-radius: 15px;
      border: 2px solid #86868B;
      display: inline-block;
      margin: 0 4px;
      cursor: pointer;
      &.slick-active {
        background-color: #86868B; } } } }

.products-index-block {
  background-color: #E8E8E8;
  padding: 30px 0; }

.products-index-block-h4 {
  color: #000;
  font-size: 48px;
  font-weight: bold; }

.products-index-block-text {
  margin-top: 25px;
  color: #000;
  font-size: 28px;
  line-height: 32px; }

.products-index-sliders {
  background-color: #1F2431;
  padding: 35px 0; }

.products-index-sliders-h4 {
  margin-top: 45px;
  font-size: 48px;
  color: #fff;
  font-weight: bold;

  &:first-child {
    margin-top: 0; }

  span {
    background: linear-gradient(45deg, #9ACFFA, #9B86DE, #E29DBE, #EEDCCE);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; } }

.products-index-sliders-text {
  font-size: 28px;
  line-height: 32px;
  color: #8D96B2;
  margin: 25px 0; }

#productModal, #productSuccessModal, #homeSuccessModal {
  .modal-content {
    background-image: url("/img/form-bg.jpg");
    background-size: cover;
    padding: 40px 20px; }

  .close {
    color: #fff;
    font-weight: 200;
    font-size: 24px; } }

.product-modal-h3 {
  font-size: 35px;
  font-weight: bold;
  color: #fff;
  text-align: left;
  margin-bottom: 40px; }

.productInput {
  background-color: rgb(147, 125, 159);
  display: block;
  border-radius: 16px;
  padding: 14px 20px;
  width: 80%;
  margin: 10px auto;
  color: #fff;
  font-size: 17px;

  &::placeholder {
    color: #fff; } }

.product-modal-submit, .home-modal-submit {
  background-color: #000;
  color: #fff;
  padding: 10px 20px;
  font-size: 17px;
  font-weight: bold;
  border-radius: 20px;
  margin-top: 40px; }

.home-modal-submit {
  margin-top: 10px; }

.product-success-modal-list {
  text-align: left;

  li {
    color: #fff;
    font-size: 21px;
    line-height: 26px;
    margin-top: 25px;
    &:before {
      content: "";
      display: inline-block;
      width: 7px;
      height: 7px;
      border-radius: 7px;
      background-color: #fff;
      vertical-align: middle;
      margin: 0 17px; } } }

.home1 {
  padding: 35px 0; }

.home1-text, .home2-h2, .home3-h2, .home4-h2, .home5-h2, .home7-h2 {
  color: #000;
  font-size: 48px;
  font-weight: bold;
  line-height: 58px; }

.home3-h2 {
  color: #fff; }

.home2 {
  background-color: #E8E8E8;
  padding: 35px 0; }

.home2-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  li {
    margin-top: 30px;
    width: calc(50% - 20px);
    color: #000;
    font-size: 24px;
    line-height: 30px; } }

.home3 {
  background-color: #1F2431;
  padding: 35px 0;

  .rate-variables {
    margin-top: 25px; }

  .select2-container--krajee .select2-selection--single .select2-selection__rendered {
    color: #fff; }

  .select2-container--krajee .select2-selection--single .select2-selection__arrow b {
    background: url("/img/selectWhite.svg");
    height: 9px; }

  .rate-item {
    border: none;
    background: none;
    background-image: url("/img/bgRate.svg");
    background-size: cover; }

  .rate-item__title, .rate-item__price {
    color: #fff; }

  .rate-item__item {
    color: #fff;
    text-align: left;
    font-size: 16px;
    margin-top: 15px; }

  .rate-slider .slick-arrow.slick-next, .rate-slider .slick-arrow.slick-prev {
    background-image: url("/img/selectWhite.svg") !important;
    background-repeat: no-repeat;
    background-position: center;
    background-color: rgb(88, 91, 99);
    border-radius: 48px;
    transform: rotate(-90deg); }

  .rate-slider .slick-arrow.slick-prev {
    transform: rotate(90deg); } }

.home3-text {
  font-size: 35px;
  line-height: 43px;
  color: #8D96B2;
  margin-top: 35px;
  a {
    color: #fff;
    text-decoration: underline; } }

.home4 {
  padding: 35px 0; }

.home4-list {
  li {
    color: #000;
    margin-top: 20px;
    font-size: 24px;
    line-height: 28px;
    a {
      color: #315EFB;
      text-decoration: underline; } } }

.home-form {
  background-color: #1F2431;
  padding: 150px 0; }

.home-form-h2 {
  font-size: 38px;
  line-height: 50px; }

.home-form-text {
  color: #8D96B2;
  font-size: 24px;
  line-height: 30px;
  margin-top: 25px;
  a {
    color: #fff;
    text-decoration: underline; } }

.home5 {
  background-color: #E8E8E8;
  padding: 35px 0; }

.home5-list {
  margin-top: 40px; }

.home5-number, .home5-question {
  color: #000;
  font-size: 28px;
  font-weight: 500; }

.home5-item {
  margin-top: 15px;
  &.open {
    .home5-question {
      &:after {
        transform: rotate(180deg); } }
    .home5-answer {
      display: block; } } }

.home5-question {
  padding-left: 35px;
  padding-bottom: 10px;
  padding-right: 40px;
  position: relative;
  border-bottom: 1px solid #86868B;
  line-height: 33px;
  cursor: pointer;
  &:after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 7px;
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    border: 1px solid #000;
    background-image: url("/img/path.svg");
    background-repeat: no-repeat;
    background-position: center; } }

.home5-number {
  position: absolute;
  left: 0; }

.home5-answer {
  color: #000;
  font-size: 17px;
  font-weight: 500;
  padding-left: 30px;
  margin-top: 12px;
  display: none; }

.home6 {
  background: #1F2431;
  padding: 30px 0; }

.home6-h2 {
  font-size: 48px;
  font-weight: bold;
  line-height: 58px; }

.home6-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 30px; }

.home6-item {
  width: calc(100% / 3 - 10px);
  height: 200px;
  background-image: url("/img/form-bg.jpg");
  border-radius: 15px;
  display: flex;
  align-items: end;
  padding: 17px;
  margin-bottom: 15px;
  a {
    cursor: pointer;
    &:hover {
      text-decoration: underline; } } }

.home7 {
  background: #E8E8E8;
  padding: 30px 0; }

.home7-text {
  color: #000;
  font-size: 32px;
  font-weight: 500;
  line-height: 38px;
  margin-top: 20px; }

.home8 {
  .application {
    background-image: url("/img/form-bg.jpg"); }
  .capability-content__mainTitle, .capability-content__title {
    color: #fff; }
  .capability-content__text, .about-text {
    color: #D0D0D0; } }

.home-view {
  .tab-content {
    background: #fff; }

  .tariff__inner {
    padding: 0; }

  .tariff-tabs {
    margin-top: 0;
    padding-top: 50px;
    background: #1F2431; }

  .tab-content-wrapper {
    background-color: #fff; }

  .tariff-tabs>.nav-tabs>li.active>a {
    color: #fff;
    background-color: rgb(88, 91, 99);
    border: 2px solid rgb(88, 91, 99); }

  .tariff-tabs>.nav-tabs>li>a {
    color: #868686;
    background: none;
    border: 2px solid #868686;
    border-radius: 20px; }

  #select2-chooseLocation-container {
    color: #fff; }

  .select2-container--krajee .select2-selection--single .select2-selection__arrow b {
    background: url("/img/selectWhite.svg");
    background-repeat: no-repeat; }

  .tariff-content {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0; } }

.tariff-select-wrapper {
  background: #1F2431;
  padding-top: 50px; }

.tariff-list-wrapper_new {
  background: #E8E8E8;
  padding: 40px 0; }

.tariff-list-title_new {
  color: #000;
  font-size: 48px;
  line-height: 58px;
  font-weight: bold;
  max-width: 60%; }

.tariff-list_new {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  li {
    font-size: 24px;
    line-height: 30px;
    margin-top: 25px;
    color: #000;
    width: calc(50% - 40px); } }

.tariff__title-home_new {
  text-align: left; }

.tariff-result_wrapper {
  background-image: url("/img/form-bg.jpg");
  background-size: cover;

  .tariff-result {
    background: none;
    padding-left: 0;
    padding-right: 0; }

  .tariff-result__inner {
    margin: 0;
    max-width: none; }

  .options-connect__checkbox-input {
    background: #000; }

  .options-connect__checkbox-label {
    color: #fff;
    p {
      margin: 0;
      margin-top: 5px; } }

  .select2-selection.select2-selection--single {
    background: rgb(144, 125, 160) !important; }

  #select2-number_ip-container, #select2-router-container {
    color: #fff; }

  .total-list-item-text, .tariff-result__total-title {
    color: #fff; } }

.home-view {
  .connect {
    padding: 35px 0; }

  .connect-h3 {
    color: #000;
    font-size: 48px;
    line-height: 58px;
    font-weight: bold; }

  .connect-list {
    color: #000;
    font-size: 24px;
    line-height: 34px;

    li {
      margin-top: 20px; } }

  .tariff-result__select-checkbox {
    max-width: 280px; } }

.home_bottom {
  padding: 170px 0;
  background-image: url("/img/connect_bg.jpg");
  background-size: cover;
  text-align: center;

  a {
    color: #fff;
    background: #000;
    font-size: 17px;
    display: inline-block;
    padding: 10px 20px;
    border-radius: 41px;
    margin-top: 40px; } }

.home_bottom-h3 {
  color: #fff;
  font-size: 48px;
  font-weight: bold;
  line-height: 58px;
  text-align: center; }

.home_bottom-text {
  font-size: 24px;
  color: #fff;
  line-height: 34px;
  text-align: center;
  margin-top: 35px; }

.pay-wrapper {
  max-width: 100%;
  width: 658px;
  height: 350px;
  margin: 150px auto;
  position: relative; }

.pay-front, .pay-back {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(359.61deg,rgba(169,201,255,.65) .34%,rgba(255,187,236,.65) 99.68%);
  padding: 25px;
  border-radius: 25px;
  transition: 1s;
  backface-visibility: hidden;

  &:after {
    content: "";
    background-image: url(../img/options_image/Visa.svg);
    background-size: cover;
    width: 109px;
    height: 29px;
    display: inline-block;
    position: absolute;
    right: 25px;
    top: 25px; } }

.pay-front.no-active {
  transform: rotateY(180deg); }

.pay-back.no-active {
  transform: rotateY(180deg); }

.pay-h1 {
  max-width: 60%;
  font-size: 32px;
  font-weight: bold;
  color: #000;
  margin-bottom: 40px; }

.pay-input {
  width: 250px;
  max-width: 100%;
  display: block;
  margin: 15px auto;
  background: rgba(#fff, .5%);
  border: none;
  border-radius: 25px;
  font-size: 14px;
  padding: 15px 30px;
  color: #000; }

.pay-btn, .pay-btn-back, .pay-btn-pay {
  background: #000;
  padding: 7px;
  width: 120px;
  border-radius: 18px;
  display: block;
  margin: 0 auto;
  margin-top: 30px;
  cursor: pointer;
  text-align: center; }

#payForm {
  position: relative; }

.pay-error-numdogovor {
  display: none;
  position: absolute;
  color: red;
  top: -27px;
  right: 0;
  left: 0;
  text-align: center;
  font-size: 14px; }

.pay-list {
  background: #fff;
  padding: 20px;
  width: 400px;
  max-width: 100%;
  margin: 0 auto;
  border-radius: 18px; }

.pay-item {
  font-size: 14px;
  color: #000;
  margin-bottom: 10px;
  font-weight: bold;
  &:last-child {
    margin: 0; }
  span {
    font-weight: normal;
    margin-left: 15px; } }

.wrapper-pay-btns {
  display: flex;
  justify-content: space-between;
  width: 300px;
  margin: 0 auto; }



@import "media";
